import React, { useState } from "react";
import Modal from "react-modal";
import PouchInput from "components/PouchInput";
import PouchColorPicker from "components/PouchColorPicker";
import { Meet } from "types";
import get from "lodash/get";
import map from "lodash/map";
import Select from "components/select/Select";
import { updateAttributesOnDocument } from "util/pouchActions";

const manufactureOptions: {
  label: string | undefined | null;
  value: string;
}[] = [
  { label: "Eleiko", value: "Eleiko" },
  { label: "Ivanko", value: "Ivanko" },
  { label: "Rogue Fitness", value: "Rogue Fitness" },
  { label: "Texas Strength Systems", value: "Texas Strength Systems" },
  { label: "Titan", value: "Titan" },
];

const manufactureColorsKg: Record<string, Record<string, string>> = {
  Eleiko: {
    plate_50: "#0F1718",
    plate_25: "#88262D",
    plate_20: "#143D70",
    plate_15: "#B09931",
    plate_10: "#4B6D3B",
    plate_5: "#C5CBD1",
    plate_2_5: "#0F1718",
    plate_2: "#90A1AB",
    plate_1_5: "#90A1AB",
    plate_1_25: "#90A1AB",
    plate_1: "#90A1AB",
    plate_0_5: "#90A1AB",
    plate_0_25: "#90A1AB",
  },
  Ivanko: {
    plate_50: "#5CB85C",
    plate_25: "#C25140",
    plate_20: "#00008B",
    plate_15: "#CCCC00",
    plate_10: "#000000",
    plate_5: "#000000",
    plate_2_5: "#000000",
    plate_2: "#000000",
    plate_1_5: "#000000",
    plate_1_25: "#000000",
    plate_1: "#000000",
    plate_0_5: "#000000",
    plate_0_25: "#000000",
  },
  "Rogue Fitness": {
    plate_50: "#1A191D",
    plate_25: "#933237",
    plate_20: "#35557C",
    plate_15: "#B69637",
    plate_10: "#3B8435",
    plate_5: "#E5E6E8",
    plate_2_5: "#1A191D",
    plate_2: "#C7C8CA",
    plate_1_5: "#C7C8CA",
    plate_1_25: "#C7C8CA",
    plate_1: "#C7C8CA",
    plate_0_5: "#C7C8CA",
    plate_0_25: "#C7C8CA",
  },

  "Texas Strength Systems": {
    plate_50: "#000000",
    plate_25: "#FF0000",
    plate_20: "#0000FF",
    plate_15: "#CCCC00",
    plate_10: "#00CC00",
    plate_5: "#FFFFFF",
    plate_2_5: "#000000",
    plate_2: "#9FA1A0",
    plate_1_5: "#9FA1A0",
    plate_1_25: "#FFD700",
    plate_1: "#9FA1A0",
    plate_0_5: "#9FA1A0",
    plate_0_25: "#9FA1A0",
  },
  Titan: {
    plate_50: "#24262D",
    plate_25: "#692B2E",
    plate_20: "#2A3952",
    plate_15: "#917636",
    plate_10: "#3E5D30",
    plate_5: "#B4B5B7",
    plate_2_5: "#24262D",
    plate_2: "#9FA1A0",
    plate_1_5: "#9FA1A0",
    plate_1_25: "#9FA1A0",
    plate_1: "#9FA1A0",
    plate_0_5: "#9FA1A0",
    plate_0_25: "#9FA1A0",
  },
};

const manufactureColorsLbs: Record<string, Record<string, string>> = {
  Eleiko: {
    plate_100: "#0F1718",
    plate_55: "#88262D",
    plate_45: "#143D70",
    plate_35: "#B09931",
    plate_25: "#4B6D3B",
    plate_10: "#C5CBD1",
    plate_5: "#0F1718",
    plate_2_5: "#90A1AB",
    plate_1_25: "#90A1AB",
    plate_0_5: "#90A1AB",
    plate_0_25: "#90A1AB",
  },
  Ivanko: {
    plate_100: "#5CB85C",
    plate_55: "#C25140",
    plate_45: "#00008B",
    plate_35: "#CCCC00",
    plate_25: "#5CB85C",
    plate_10: "#FFFFFF",
    plate_5: "#000000",
    plate_2_5: "#000000",
    plate_1_25: "#000000",
    plate_0_5: "#000000",
    plate_0_25: "#000000",
  },
  "Rogue Fitness": {
    plate_100: "#1A191D",
    plate_55: "#933237",
    plate_45: "#35557C",
    plate_35: "#B69637",
    plate_25: "#3B8435",
    plate_10: "#E5E6E8",
    plate_5: "#1A191D",
    plate_2_5: "#C7C8CA",
    plate_1_25: "#C7C8CA",
    plate_0_5: "#C7C8CA",
    plate_0_25: "#C7C8CA",
  },

  "Texas Strength Systems": {
    plate_100: "#000000",
    plate_55: "#FF0000",
    plate_45: "#0000FF",
    plate_35: "#CCCC00",
    plate_25: "#00CC00",
    plate_10: "#FFFFFF",
    plate_5: "#000000",
    plate_2_5: "#9FA1A0",
    plate_1_25: "#FFD700",
    plate_0_5: "#9FA1A0",
    plate_0_25: "#9FA1A0",
  },
  Titan: {
    plate_100: "#24262D",
    plate_55: "#692B2E",
    plate_45: "#2A3952",
    plate_35: "#917636",
    plate_25: "#3E5D30",
    plate_10: "#B4B5B7",
    plate_5: "#24262D",
    plate_2_5: "#9FA1A0",
    plate_1_25: "#9FA1A0",
    plate_0_5: "#9FA1A0",
    plate_0_25: "#9FA1A0",
  },
};

const Plates = ({ meet }: { meet: Meet }) => {
  const [presetModalOpen, setPresetModalOpen] = useState(false);
  const [manufacture, setManufacture] = useState("");
  const onClickSetManufacture = () => {
    const plates = { ...meet.plates };
    const colors =
      meet.units === "KG"
        ? manufactureColorsKg[manufacture]
        : manufactureColorsLbs[manufacture];
    Object.keys(plates).forEach((plateKey) => {
      if (colors[plateKey]) {
        plates[plateKey].color = colors[plateKey];
      }
    });
    updateAttributesOnDocument(meet._id, meet._id, { plates });
    setManufacture("");
    setPresetModalOpen(false);
  };

  return (
    <div className="plates">
      <table>
        <thead>
          <tr>
            <th>Plate</th>
            <th># of pairs</th>
            <th>Color</th>
          </tr>
        </thead>
        <tbody>
          {map(meet.plates, (plate, key) => {
            return (
              <tr key={key}>
                <th>
                  {plate.weight} {meet.units}
                </th>
                <th>
                  <PouchInput
                    style={{ width: 100 }}
                    type="number"
                    meetId={meet._id}
                    documentId={meet._id}
                    name={`plates.${key}.pairsCount`}
                    value={get(meet, ["plates", key, "pairsCount"])}
                  />
                </th>
                <th>
                  <PouchColorPicker
                    meet={meet}
                    document={meet}
                    name={`plates.${key}.color`}
                    value={get(meet, ["plates", key, "color"])}
                  />
                </th>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div style={{ margin: 20 }}>
        <button onClick={() => setPresetModalOpen(true)}>
          Set Plate Colors to Manufacture Preset
        </button>
      </div>

      <Modal
        ariaHideApp={false}
        isOpen={presetModalOpen}
        onRequestClose={() => setPresetModalOpen(false)}
        shouldCloseOnOverlayClick={false}
        className={{
          base: "upload-modal",
          afterOpen: "upload-modal-after-open",
          beforeClose: "upload-modal-before-close",
        }}
        overlayClassName={{
          base: "upload-modal-overlay",
          afterOpen: "upload-modal-overlay-after-open",
          beforeClose: "upload-modal-overlay-before-close",
        }}
        contentLabel="upload modal"
      >
        <div className="content">
          <h2>Set Plate Colors</h2>
          <Select
            blankValueLabel="Select a Manufacture"
            name="manufacture"
            value={manufacture}
            options={manufactureOptions}
            onChange={(v) => setManufacture(typeof v === "string" ? v : "")}
          />
          <div className="button-row" style={{ marginTop: 40 }}>
            <input
              className="password-cancel-button"
              type="button"
              onClick={() => setPresetModalOpen(false)}
              value="Cancel"
            />
            <input
              disabled={!manufacture}
              type="submit"
              onClick={onClickSetManufacture}
              value="Set"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Plates;
