import React from "react";
import get from "lodash/get";
import classNames from "classnames";

import CheckIcon from "icons/CheckIcon";
import "./Lights.scss";
import { Meet, RefDecision, RefPosition } from "types";

export const RefLight = ({
  refDecision,
  showDecisions,
  showChecks,
  lightStyle,
  iconStyle,
  cardsStyle,
}: {
  refDecision: RefDecision | undefined | null;
  showDecisions: boolean;
  showChecks: boolean;
  lightStyle?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
  cardsStyle?: React.CSSProperties;
}) => {
  return (
    <div className="decision">
      <div
        className={classNames("light", showDecisions && refDecision?.decision)}
        style={lightStyle}
      >
        {refDecision?.decision && showChecks && !showDecisions && (
          <CheckIcon style={iconStyle} />
        )}
      </div>
      <div className="cards" style={cardsStyle}>
        <div
          className={classNames("card", "red", {
            active: refDecision?.cards?.red && showDecisions,
          })}
        />
        <div
          className={classNames("card", "blue", {
            active: refDecision?.cards?.blue && showDecisions,
          })}
        />
        <div
          className={classNames("card", "yellow", {
            active: refDecision?.cards?.yellow && showDecisions,
          })}
        />
      </div>
    </div>
  );
};

type LightsProps = {
  meet: Meet;
  platformId: string;
  showChecks?: boolean;
  style?: { height: number };
};

const Lights = ({ meet, platformId, showChecks, style }: LightsProps) => {
  const getDecision = (position: RefPosition) => {
    return get(meet, ["platforms", platformId, "refs", position, "decision"]);
  };

  let wrapperStyle = {};
  let lightStyle = {};
  let iconStyle = {};
  let cardsStyle = {};
  if (style) {
    const lightSize = Math.floor(style.height * 0.85);
    const checkSize = Math.floor(style.height * 0.5);
    wrapperStyle = { height: style.height, width: style.height * 4 };
    lightStyle = {
      height: lightSize,
      width: lightSize,
      borderRadius: Math.floor(lightSize / 2),
    };
    iconStyle = { height: checkSize, width: checkSize };
    cardsStyle = {
      height: Math.floor(style.height * 0.12),
      width: lightSize,
    };
  }

  const showDecisions =
    getDecision("left") && getDecision("head") && getDecision("right");

  return (
    <div className="lights" style={wrapperStyle}>
      <RefLight
        refDecision={get(meet, ["platforms", platformId, "refs", "left"])}
        showDecisions={showDecisions}
        showChecks={!!showChecks}
        lightStyle={lightStyle}
        iconStyle={iconStyle}
        cardsStyle={cardsStyle}
      />
      <RefLight
        refDecision={get(meet, ["platforms", platformId, "refs", "head"])}
        showDecisions={showDecisions}
        showChecks={!!showChecks}
        lightStyle={lightStyle}
        iconStyle={iconStyle}
        cardsStyle={cardsStyle}
      />
      <RefLight
        refDecision={get(meet, ["platforms", platformId, "refs", "right"])}
        showDecisions={showDecisions}
        showChecks={!!showChecks}
        lightStyle={lightStyle}
        iconStyle={iconStyle}
        cardsStyle={cardsStyle}
      />
    </div>
  );
};

export default Lights;
