import { Column } from "components/table/Table";
import React from "react";
import { Meet } from "types";

import { getSubtotal } from "util/lifterHelper";

const TotalCell = ({
  data,
  column,
  meet,
  style,
}: {
  data: any;
  column: Column;
  meet: Meet;
  style: React.CSSProperties & { fontSize: number };
}) => {
  const lifter = data;
  if (lifter.row === "header") {
    return (
      <div style={style} className="table-cell-inner">
        {column.label}
      </div>
    );
  } else {
    return (
      <div style={style} className="table-cell-inner">
        {getSubtotal(data, meet)}
      </div>
    );
  }
};

export default TotalCell;
