import React, { useEffect, useState } from "react";
import get from "lodash/get";

// @ts-ignore
import setQuery from "set-query-string";

import queryString from "query-string";

import FixedHeightWrapper from "app/meets/FixedHeightWrapper";
import Lights from "components/lights/Lights";
import BoardTable from "./BoardTable";
import Clock from "components/clock/Clock";
import CheckIcon from "icons/CheckIcon";
import GearIcon from "icons/GearIcon";
import NextAttemptInputTimers from "./NextAttemptInputTimers";
import { usePlatform } from "util/usePlatform";
import { useMeet } from "util/useMeet";
import { useFedConfig } from "util/useFedConfig";
import { useScoreBoardTableData } from "util/useScoreBoardTableData";
import { useCurrentAttempt } from "util/useCurrentAttempt";
import { useCurrentLifter } from "util/useCurrentLifter";
import { useMediaSize } from "util/useMediaSize";

import "./BoardIndex.scss";

export const BoardIndex = () => {
  const [scrollToCurrentFlight, setScrollToCurrentFlight] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);

  const meet = useMeet();
  const platform = usePlatform();
  const federationConfig = useFedConfig();
  const tableData = useScoreBoardTableData();
  const currentAttempt = useCurrentAttempt();
  const currentLifter = useCurrentLifter();
  const media = useMediaSize();

  useEffect(() => {
    if (meet.boardDefaultURL && !window.location.search) {
      window.location.search = meet.boardDefaultURL;
    }
  }, [meet.boardDefaultURL]);

  useEffect(() => {
    if (window.location.search) {
      const queryData = queryString.parse(window.location.search);
      const scrollToCurrentFlight = queryData["scroll"];
      if (scrollToCurrentFlight === "true") {
        setScrollToCurrentFlight(true);
      }
    }
  }, []);

  const toggleScrollToCurrentFlight = () => {
    setQuery({ scroll: !scrollToCurrentFlight });
    setScrollToCurrentFlight((prev) => !prev);
  };

  const openSettingsModal = () => {
    setIsSettingsModalOpen(true);
  };

  const closeSettingsModal = () => {
    setIsSettingsModalOpen(false);
  };

  return (
    <FixedHeightWrapper>
      <div className="board-index">
        <div className="util-row">
          <div className="clock">
            <Clock platform={platform} />
          </div>
          <Lights
            meet={meet}
            platformId={get(platform, "_id")}
            style={{ height: 35 }}
          />
          <button className="auto-scroll" onClick={toggleScrollToCurrentFlight}>
            Auto Scroll &nbsp;
            {scrollToCurrentFlight && <CheckIcon />}
          </button>
          <button onClick={openSettingsModal}>
            <GearIcon />
          </button>
          <NextAttemptInputTimers />
        </div>
        <div className="table-wrapper">
          <BoardTable
            meet={meet}
            sortedLifters={tableData}
            currentAttempt={currentAttempt}
            currentLifter={currentLifter}
            scrollToCurrentFlight={scrollToCurrentFlight}
            media={media}
            isSettingsModalOpen={isSettingsModalOpen}
            closeSettingsModal={closeSettingsModal}
            federationConfig={federationConfig}
          />
        </div>
      </div>
    </FixedHeightWrapper>
  );
};

export default BoardIndex;
