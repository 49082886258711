import React, { useState } from "react";
import { Link } from "react-router-dom";

import "./Contact.scss";

const Contact = () => {
  const [personType, setPersonType] = useState("");

  return (
    <div className="contact">
      <div>
        <Link to="/">Go Home</Link>
      </div>
      <div className="contact-contents">
        <h1>Contact LiftingCast</h1>
        {!personType && (
          <div>
            <button onClick={() => setPersonType("lifter")}>
              I'm a lifter
            </button>
            <button onClick={() => setPersonType("lifter")}>I'm a coach</button>
            <button onClick={() => setPersonType("director")}>
              I'm a meet director or scoring manager
            </button>
          </div>
        )}
        {personType === "lifter" && (
          <div>
            Please contact the meet director for any questions related to the
            event. If you are not sure who the meet director is check your
            federations calendar.
          </div>
        )}
        {personType === "director" && (
          <div>
            <p>
              Please email{" "}
              <a href="mailto:mike@liftingcast.com">mike@liftingcast.com</a>.
            </p>
            <p>
              When contacting support be sure to include a link to the meet you
              are working on. I will try to respond within 24 hours. Online
              support for this product is limited. Don't rely on support the day
              of your meet. Please make sure you data is imported and you are
              able to log in to all computers at least 24 hours in advance of
              the meet.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Contact;
