import { useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  getIsLoading,
  getIsLocal,
  getIsLoggedIn,
  getIsOnline,
  getIsSyncActive,
  getIsSyncing,
  getNotFound,
} from "selectors";
import { ReduxState } from "types";

export const useStatus = () => {
  const { meetId } = useParams<{ meetId: string }>();

  const isOnline = useSelector((state: ReduxState) =>
    getIsOnline(state, { match: { params: { meetId } } })
  );
  const isLocal = useSelector((state: ReduxState) =>
    getIsLocal(state, { match: { params: { meetId } } })
  );

  const isLoggedIn = useSelector((state: ReduxState) =>
    getIsLoggedIn(state, { match: { params: { meetId } } })
  );

  const isSyncing = useSelector((state: ReduxState) =>
    getIsSyncing(state, { match: { params: { meetId } } })
  );

  const isSyncActive = useSelector((state: ReduxState) =>
    getIsSyncActive(state, { match: { params: { meetId } } })
  );

  const isLoading = useSelector((state: ReduxState) =>
    getIsLoading(state, { match: { params: { meetId } } })
  );

  const isNotFound = useSelector((state: ReduxState) =>
    getNotFound(state, { match: { params: { meetId } } })
  );

  return {
    isOnline,
    isLocal,
    isLoggedIn,
    isSyncing,
    isSyncActive,
    isLoading,
    isNotFound,
  };
};
