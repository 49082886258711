import React from "react";
import {
  getDivisionDoc,
  getLifterAwardsWeightClassDoc,
} from "util/lifterHelper";
import ResultsTable from "app/meets/results/ResultsTable";
import map from "lodash/map";
import { useLifter } from "util/useLifter";
import { useMeet } from "util/useMeet";
import filter from "lodash/filter";
import { Division, WeightClass } from "types";
import compact from "lodash/compact";

const LifterResults = () => {
  const meet = useMeet();
  const lifter = useLifter();
  const divisions: (Omit<Division, "weightClasses"> & {
    weightClasses: WeightClass[];
  })[] = lifter
    ? compact(
        map(lifter.divisions, (lifterDivision) => {
          const division = getDivisionDoc(lifterDivision.divisionId, meet);
          if (!division) {
            return;
          }
          const weightClasses = filter(division.weightClasses, (w) => {
            const lifterWeightClass = getLifterAwardsWeightClassDoc(
              meet,
              lifter,
              lifterDivision
            );
            return (
              "_id" in lifterWeightClass && lifterWeightClass._id === w._id
            );
          });

          return { ...division, weightClasses };
        })
      )
    : [];

  return (
    <div className="lifter-results">
      <ResultsTable meet={meet} divisions={divisions} showButtons={false} />
    </div>
  );
};

export default LifterResults;
