import React from "react";
import Modal from "react-modal";
import RadioGroup from "components/radioGroup/RadioGroup";
import FileIcon from "icons/FileIcon";
import { exportAwardsResults, exportFederationResults } from "util/exportData";
import { exportOpenPowerliftingResults } from "util/openPowerlifting";
import { Meet } from "types";
import { useStatus } from "util/useStatus";
import InvoiceStatus from "components/invoiceStatus/InvoiceStatus";

const ExportResultsModal = ({
  meet,
  data,
  onRequestClose,
  isOpen,
}: {
  meet: Meet;
  data: any;
  onRequestClose: () => void;
  isOpen: boolean;
}) => {
  const [format, setFormat] = React.useState("LIFTINGCAST");
  const [meetCountry, setMeetCountry] = React.useState("");
  const [meetState, setMeetState] = React.useState("");
  const [meetTown, setMeetTown] = React.useState("");

  const { isLocal } = useStatus();

  const onRadioSelect = (value: string) => {
    setFormat(value);
  };

  const exportResults = () => {
    if (format === "LIFTINGCAST") {
      exportAwardsResults(meet, data, isLocal);
    } else if (format === "OPEN_POWERLIFTING") {
      exportOpenPowerliftingResults({
        meet,
        dataArray: data,
        meetCountry,
        meetState,
        meetTown,
      });
    } else if (format === "FEDERATION") {
      exportFederationResults(meet, data);
    }

    onRequestClose();
  };

  const exportOptions = [
    { label: "LiftingCast Standard", value: "LIFTINGCAST" },
    { label: "Open Powerlifting", value: "OPEN_POWERLIFTING" },
  ];

  if (meet.federation !== "OTHER" && meet.federation !== "WPP" && isLocal) {
    const federation = meet.federation || "Federation";
    exportOptions.push({
      label: `${federation} Standard`,
      value: "FEDERATION",
    });
  }

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      contentLabel="Export Results"
      onRequestClose={onRequestClose}
      className={{
        base: "export-results-modal",
        afterOpen: "export-results-modal-after-open",
        beforeClose: "export-results-modal-before-close",
      }}
      overlayClassName={{
        base: "export-results-modal-overlay",
        afterOpen: "export-results-modal-overlay-after-open",
        beforeClose: "export-results-modal-overlay-before-close",
      }}
    >
      <div className="content">
        {isLocal && <InvoiceStatus />}
        <h1>Select Format</h1>
        <RadioGroup
          onCheck={onRadioSelect}
          value={format}
          items={exportOptions}
        />
        {format === "OPEN_POWERLIFTING" && (
          <div>
            There is some additional information that the OpenPowerlifting
            format requires.
            <div className="input-wrapper">
              <label>
                Meet Country:
                <input
                  type="text"
                  name="MeetCountry"
                  value={meetCountry}
                  onChange={(e) => setMeetCountry(e.target.value)}
                />
              </label>
            </div>
            <div className="input-wrapper">
              <label>
                Meet State:
                <input
                  type="text"
                  name="MeetState"
                  value={meetState}
                  onChange={(e) => setMeetState(e.target.value)}
                />
              </label>
            </div>
            <div className="input-wrapper">
              <label>
                Meet Town:
                <input
                  type="text"
                  name="MeetTown"
                  value={meetTown}
                  onChange={(e) => setMeetTown(e.target.value)}
                />
              </label>
            </div>
          </div>
        )}
      </div>

      <div className="button-row">
        <button onClick={onRequestClose} style={{ marginRight: 12 }}>
          Cancel
        </button>
        <button onClick={exportResults}>
          <FileIcon /> &nbsp; Export
        </button>
      </div>
    </Modal>
  );
};

export default ExportResultsModal;
