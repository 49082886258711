import React from "react";
import toLower from "lodash/toLower";
import get from "lodash/get";
import { getHost } from "util/api";
import { Column } from "components/table/Table";

const FlagCell = ({
  data,
  column,
  style,
}: {
  data: any;
  column: Column;
  style: React.CSSProperties & { fontSize: number };
}) => {
  const lifter = data;
  if (lifter.row === "header") {
    return (
      <div style={style} className="table-cell-inner">
        {column.label}
      </div>
    );
  } else {
    const country = toLower(get(lifter, "country"));
    const host = getHost();
    const imgStyle: React.CSSProperties = {};

    if (country) {
      imgStyle.height = "100%";
      imgStyle.width = "100%";
      imgStyle.backgroundImage = `url('${host}/flags/${country}.svg')`;
      imgStyle.backgroundSize = "contain";
      imgStyle.backgroundRepeat = "no-repeat";
      imgStyle.backgroundPosition = "top left";
    }

    return (
      <div style={style} className="table-cell-inner">
        {country && <div style={imgStyle} />}
      </div>
    );
  }
};

export default FlagCell;
