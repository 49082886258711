import React, { useEffect } from "react";
import Lights from "./Lights";
import LiftDetails from "./LiftDetails";
import Timer from "./Timer";
import LoadingChart from "./LoadingChart";
import NextLoad from "./NextLoad";
import OrderTable from "./OrderTable";
import PositionChange from "./PositionChange";
import "./DisplayIndex.scss";
import { useMeet } from "util/useMeet";
import { usePlatform } from "util/usePlatform";
import { useCurrentLifter } from "util/useCurrentLifter";
import { useCurrentAttempt } from "util/useCurrentAttempt";
import { useKnownLiftingOrder } from "util/useKnownLiftingOrder";
import { useCompleteLiftingOrder } from "util/useCompleteLiftingOrder";
import { useFedConfig } from "util/useFedConfig";

const DisplayIndex = () => {
  const meet = useMeet();
  const platform = usePlatform();
  const currentLifter = useCurrentLifter();
  const currentAttempt = useCurrentAttempt();
  const knownLiftingOrder = useKnownLiftingOrder();
  const completeLiftingOrder = useCompleteLiftingOrder();
  const federationConfig = useFedConfig();

  useEffect(() => {
    if (meet.displayDefaultURL && !window.location.search) {
      window.location.search = meet.displayDefaultURL;
    }
  }, [meet.displayDefaultURL]);

  useEffect(() => {
    let colorStyle = "";
    let backgroundStyle = "";
    let backgroundColor = "";

    if (meet.displayFontColor) {
      colorStyle = `color: ${meet.displayFontColor};`;
    }

    if (meet.displayBackgroundColor) {
      backgroundColor = `background-color: ${meet.displayBackgroundColor};`;
    }

    if (meet.displayBackgroundImageUrl) {
      backgroundStyle = meet.displayBackgroundImageUrl
        ? `background: url(${meet.displayBackgroundImageUrl}) no-repeat fixed; background-size: cover;`
        : "";
    }

    const styleString = `${colorStyle}${backgroundStyle}${backgroundColor}`;
    document.body.setAttribute("style", styleString);

    return () => document.body.setAttribute("style", "");
  }, [
    meet.displayBackgroundColor,
    meet.displayBackgroundImageUrl,
    meet.displayFontColor,
  ]);

  if (!platform || !meet) {
    return null;
  }

  return (
    <div className="display-index">
      <div style={{ position: "relative", width: "100%", height: "100%" }}>
        <OrderTable
          meet={meet}
          platform={platform}
          completeLiftingOrder={completeLiftingOrder}
          id="o"
          federationConfig={federationConfig}
        />
        <LiftDetails
          meet={meet}
          currentLifter={currentLifter}
          currentAttempt={currentAttempt}
          id="d"
        />
        <Timer platform={platform} id="time" />
        <LoadingChart
          meet={meet}
          currentLifter={currentLifter}
          currentAttempt={currentAttempt}
          platform={platform}
          id="c"
        />
        <NextLoad
          meet={meet}
          currentAttempt={currentAttempt}
          liftingOrder={knownLiftingOrder}
          platform={platform}
          id="n"
        />
        <PositionChange
          meet={meet}
          currentAttempt={currentAttempt}
          currentLifter={currentLifter}
          id="p"
        />
        <Lights meet={meet} platformId={platform._id} id="l" />
      </div>
    </div>
  );
};

export default DisplayIndex;
