import React from "react";

import { getDivision, getWeightClass } from "selectors";
import FixedHeightWrapper from "app/meets/FixedHeightWrapper";
import DivisionResultsTable from "./DivisionResultsTable";
import { useParams } from "react-router";

import "./DivisionIndex.scss";
import { useSelector } from "react-redux";
import { ReduxState } from "types";

const DivisionIndex = () => {
  const [forecasted, setForecasted] = React.useState(false);
  const toggleForecasted = () => {
    setForecasted((prev) => !prev);
  };

  // const {
  //   division,
  //   weightClass,
  //   meet,
  //   media
  // } = this.props;

  const { meetId, weightClassId, divisionId } = useParams<{
    meetId: string;
    weightClassId: string;
    divisionId: string;
  }>();

  const props = React.useMemo(() => {
    return {
      match: {
        params: {
          weightClassId,
          divisionId,
          meetId,
        },
      },
    };
  }, [weightClassId, divisionId, meetId]);

  const division = useSelector((state: ReduxState) =>
    getDivision(state, props)
  );

  const weightClass = useSelector((state: ReduxState) =>
    getWeightClass(state, props)
  );

  if (!weightClass || !division) {
    return null;
  }

  const divisionsAndWeightClasses = [
    { ...division, weightClasses: [weightClass] },
  ];

  return (
    <FixedHeightWrapper>
      <div className="division-index">
        <DivisionResultsTable
          setForecasted={toggleForecasted}
          forecasted={forecasted}
          divisions={divisionsAndWeightClasses}
        />
      </div>
    </FixedHeightWrapper>
  );
};

// const mapStateToProps = (state, props) => {
//   return {

//     division: getDivision(state, props),
//     weightClass: getWeightClass(state, props),

//   };
// };

export default DivisionIndex;
