import React from "react";
import first from "lodash/first";
import get from "lodash/get";
import { set } from "object-path-immutable";
import DisplayComponent from "./DisplayComponent";
import { getPlace } from "util/lifterHelper";
import { Attempt, Lifter, Meet } from "types";

const getGetOrdinal = (n: number | null) => {
  if (!n) {
    return null;
  }
  const s = ["th", "st", "nd", "rd"];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

const renderPositionChange = (
  currentPlace: number | null,
  possiblePlace: number | null
) => {
  if (!currentPlace && !possiblePlace) {
    return null;
  } else if (currentPlace === possiblePlace) {
    return `If successful will stay in ${getGetOrdinal(possiblePlace)} place`;
  } else if (currentPlace && possiblePlace) {
    return `If successful will move from ${getGetOrdinal(
      currentPlace
    )} to ${getGetOrdinal(possiblePlace)} place`;
  }

  return null;
};

const PositionChange = ({
  id,
  meet,
  currentAttempt,
  currentLifter,
}: {
  id: string;
  meet: Meet;
  currentAttempt: Attempt | undefined;
  currentLifter: Lifter | undefined;
}) => {
  // defaults for configuring display screen
  let currentPlace: number | null = 10;
  let possiblePlace: number | null = 1;

  const firstDivision = first(currentLifter?.divisions ?? []);
  if (
    currentAttempt?.liftName &&
    currentAttempt?.attemptNumber &&
    currentLifter &&
    firstDivision
  ) {
    currentPlace = getPlace(currentLifter, firstDivision, meet);

    // construct version of meet where the current attempt is successful.
    const futureMeet = set(
      meet,
      [
        "lifters",
        currentLifter._id,
        "lifts",
        currentAttempt.liftName,
        currentAttempt.attemptNumber,
        "result",
      ],
      "good"
    );

    possiblePlace = getPlace(currentLifter, firstDivision, futureMeet);
  }

  return (
    <DisplayComponent id={id} style={{ top: 170, left: 0 }}>
      {({ sizeMultiplier }: { sizeMultiplier: number }) => {
        const style: React.CSSProperties = {
          padding: sizeMultiplier * 12,
          fontSize: sizeMultiplier * 24,
          width: sizeMultiplier * 600,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          whiteSpace: "nowrap",
        };

        const currentAttemptNumber = get(currentAttempt, "attemptNumber");

        return (
          <div style={style}>
            {(currentAttemptNumber === "3" || !currentAttemptNumber) &&
              renderPositionChange(currentPlace, possiblePlace)}
          </div>
        );
      }}
    </DisplayComponent>
  );
};

export default PositionChange;
