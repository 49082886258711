import { toastr } from "react-redux-toastr";
import {
  getOnlineMeetDbInstance,
  initMeet,
  createLocalMeetRecordIfNeeded,
  disableSync,
} from "util/pouchAdapter";
import { store } from "store";
import { errorReporter } from "util/errorReporter";
import { loggedInToMeet, loggedOutOfMeet } from "actions";
import { OpenPasswordModal } from "types";

export const login = function (
  meetId: string,
  password: string | null,
  openPasswordModal: OpenPasswordModal
) {
  // TODO: if meetId is blank here something is wrong, maybe meet data hasn't finished loading yet.
  // could happen if someone tries to click login right away when entering site.
  // Possibly we could parse meetId from url as fallback.
  // For now do nothing.
  if (!meetId) {
    toastr.error("Login Error", "Please try again.");
    return;
  }

  const promise = password
    ? Promise.resolve({ password })
    : openPasswordModal("get");
  return promise.then(({ password: enteredPassword }) => {
    if (!enteredPassword) {
      // canceled
      return;
    }
    const onlineDb = getOnlineMeetDbInstance(meetId);
    return onlineDb
      .login(meetId, enteredPassword)
      .then(() => {
        toastr.success("Login Success", "");
        createLocalMeetRecordIfNeeded(meetId).then(() => initMeet(meetId));
      })
      .catch((e: Error) => {
        console.log("Error on login", e);
        if (e.message === "Name or password is incorrect.") {
          toastr.error("Login Error", "Password is incorrect");
        } else {
          toastr.error("Login Error", e.message);
          errorReporter({ message: "login error", error: e });
        }
      });
  });
};

export const checkAuthStatus = function (meetId: string) {
  const onlineDb = getOnlineMeetDbInstance(meetId);
  return onlineDb
    .getSession()
    .then((response: any) => {
      if (!response.userCtx.name) {
        console.log("nobodys logged in");
        disableSync(meetId);
        return store.dispatch(loggedOutOfMeet(meetId));
      } else {
        // response.userCtx.name is the current user
        console.log("user", response.userCtx.name);
        if (response.userCtx.name === meetId) {
          return store.dispatch(loggedInToMeet(meetId));
        }
      }
    })
    .catch((err: Error) => {
      console.log("network error", err);
      toastr.error("Network Error", "");
      errorReporter({ message: "checkAuthStatus error", error: err });
      disableSync(meetId);
      store.dispatch(loggedOutOfMeet(meetId));
    });
};

export const logout = async (meetId: string) => {
  const onlineDb = getOnlineMeetDbInstance(meetId);
  await onlineDb.logout();
  await checkAuthStatus(meetId);
  toastr.success("Logout Success", "");
};
