import React from "react";

const CardAttempt = ({ liftName }: { liftName: string }) => {
  console.log("liftName", liftName);
  return (
    <div className="card">
      <div
        className="card-cell"
        style={{
          justifyContent: "center",
          fontSize: 24,
          gridColumnStart: "span 9",
          gridRowStart: "span 2",
          borderRight: 0,
        }}
      >
        {liftName}
      </div>
      <div
        className="card-cell"
        style={{
          justifyContent: "center",
          gridColumnStart: "span 3",
          gridRowStart: "span 3",
        }}
      ></div>
      <div
        className="card-cell"
        style={{
          justifyContent: "center",
          gridColumnStart: "span 3",
          gridRowStart: "span 3",
        }}
      ></div>
      <div
        className="card-cell"
        style={{
          justifyContent: "center",
          gridColumnStart: "span 3",
          gridRowStart: "span 3",
          borderRight: 0,
        }}
      ></div>
      <div
        className="card-cell"
        style={{
          justifyContent: "center",
          gridColumnStart: "span 3",
        }}
      >
        Attempt 1
      </div>
      <div
        className="card-cell"
        style={{
          justifyContent: "center",
          gridColumnStart: "span 3",
        }}
      >
        Attempt 2
      </div>
      <div
        className="card-cell"
        style={{
          justifyContent: "center",
          gridColumnStart: "span 3",
          borderRight: 0,
        }}
      >
        Attempt 3
      </div>
      <div
        className="card-cell"
        style={{
          borderBottom: 0,
          gridColumnStart: "span 9",
          borderRight: 0,
        }}
      >
        Name:
      </div>
      <div
        className="card-cell"
        style={{
          gridColumnStart: "span 9",
          gridRowStart: "span 2",
          borderRight: 0,
        }}
      ></div>
      <div
        className="card-cell"
        style={{
          borderBottom: 0,
          gridColumnStart: "span 9",
          borderRight: 0,
        }}
      >
        Lot:
      </div>
      <div
        className="card-cell"
        style={{
          gridColumnStart: "span 9",
          gridRowStart: "span 2",
          borderRight: 0,
        }}
      ></div>
      <div
        className="card-cell"
        style={{
          borderBottom: 0,
          gridColumnStart: "span 9",
          borderRight: 0,
        }}
      >
        Signature:
      </div>
      <div
        className="card-cell"
        style={{
          gridColumnStart: "span 9",
          gridRowStart: "span 2",
          borderRight: 0,
          borderBottom: 0,
        }}
      ></div>
    </div>
  );
};

export default CardAttempt;
