import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { getCompleteLiftingOrder } from "selectors";
import { LiftingOrder, ReduxState } from "types";

export const useCompleteLiftingOrder = (platformIdFromProps?: string) => {
  const { meetId, platformId } = useParams<{
    meetId: string;
    platformId: string;
  }>();

  const props = React.useMemo(() => {
    return {
      match: {
        params: { meetId, platformId: platformIdFromProps ?? platformId },
      },
    };
  }, [meetId, platformId, platformIdFromProps]);

  const completeLiftingOrder = useSelector((state: ReduxState) =>
    getCompleteLiftingOrder(state, props)
  ) as LiftingOrder;

  return completeLiftingOrder;
};
