import React from "react";
import { fetchWrapper } from "util/api";
import { useMeet } from "util/useMeet";
import queryString from "query-string";
import { Link } from "react-router-dom";
import {
  getDivisionDoc,
  getLifterAwardsWeightClassDoc,
} from "util/lifterHelper";
import { WeightClass } from "types";

const RegistrationComplete = () => {
  const meet = useMeet();
  const meetId = meet._id;
  const [loading, setLoading] = React.useState(false);
  const [complete, setComplete] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [error, setError] = React.useState("");
  const [cardCharged, setCardCharged] = React.useState(false);

  const queryData = queryString.parse(window.location.search);
  const sessionId =
    typeof queryData.sessionId === "string" ? queryData.sessionId : null;
  const lifterId =
    typeof queryData.lifterId === "string" ? queryData.lifterId : null;

  const requestRef = React.useRef(false);
  React.useEffect(() => {
    const completeReg = async () => {
      setLoading(true);
      try {
        if (!lifterId || !sessionId) {
          setLoading(false);
          setError("something went wrong");
          return;
        }
        const response = await fetchWrapper(
          `/apiv2/meets/${meetId}/registration-complete`,
          "POST",
          { lifterId, sessionId }
        );
        console.log(response);
        setLoading(false);
        if (response.ok) {
          setComplete(true);
          // TODO: remove lifterId and sessionId from url or add a complete param so we don't try again on reload
        }
        if (response.error) {
          setError(response.error);
        }
        if (response.message) {
          setMessage(response.message);
        }
        if ("cardCharged" in response) {
          setCardCharged(response.cardCarged);
        }
      } catch (e: any) {
        console.log(e);
        setError("something went wrong");
      }
    };
    if (!requestRef.current) {
      requestRef.current = true;
      completeReg();
    }
  }, [lifterId, meetId, sessionId]);

  const lifter = lifterId ? meet.lifters[lifterId] : null;
  const divisions = lifter?.divisions?.map((lifterDivision) => {
    const divisionDoc = getDivisionDoc(lifterDivision.divisionId, meet);

    const weightClassDoc: WeightClass | undefined =
      getLifterAwardsWeightClassDoc(meet, lifter, lifterDivision) as
        | WeightClass
        | undefined;

    return { division: divisionDoc, weightClass: weightClassDoc };
  });

  return (
    <div className="registration-index" style={{ fontSize: 18 }}>
      {(loading || !lifter) && (
        <>
          <h1 style={{ fontSize: 22 }}>Processing your registration</h1>
          <div className="processing-overlay" />
          <div className="processing-spinner">
            <div className="loader" />
          </div>
        </>
      )}

      {!!error && (
        <div className="api-error">
          <div>There was an error processing your request.</div>
          {!cardCharged && (
            <div>
              Your card was not charged. The pending transaction may be visible
              for a few days.
            </div>
          )}
          <div>{error}</div>
        </div>
      )}

      {complete && lifter && (
        <div>
          <h1 style={{ fontSize: 22 }}>{message}</h1>
          <div style={{ marginTop: 20 }}>{lifter.name}</div>

          <div>
            {lifter.team && (
              <Link
                to={`/meets/${meet._id}/team/${encodeURIComponent(
                  lifter.team
                )}`}
              >
                Team: {lifter.team}
              </Link>
            )}
          </div>

          <div>
            {divisions?.map((d) => {
              return (
                <div key={d.division?._id}>
                  {d.division?.name} - {d.weightClass?.name}
                </div>
              );
            })}
          </div>
          <div style={{ marginTop: 40 }}>
            <Link to={`/meets/${meet._id}/roster`}>
              You can find your name on the roster here.
            </Link>
          </div>
          <div style={{ marginTop: 20 }}>
            <Link to={`/meets/${meet._id}/lifter/${lifterId}`}>
              You can find your lifter details here.
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default RegistrationComplete;
