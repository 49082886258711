import React from "react";
import map from "lodash/map";
import { getPlace, getDivisionDoc } from "util/lifterHelper";
import { Column } from "components/table/Table";
import { Meet } from "types";

const ForecastedPlaceCell = ({
  data,
  column,
  meet,
  style,
}: {
  data: any;
  column: Column;
  meet: Meet;
  style: React.CSSProperties & { fontSize: number };
}) => {
  const lifter = data;
  if (lifter.row === "header") {
    return (
      <div style={style} className="table-cell-inner">
        {column.label}
      </div>
    );
  } else {
    return (
      <div style={style} className="table-cell-inner">
        {map(data.divisions, (lifterDivision, index) => {
          if (!lifterDivision.divisionId) {
            return null;
          }

          const division = getDivisionDoc(lifterDivision.divisionId, meet);
          if (!division) {
            return null;
          }

          if (division.hideOnBoard) {
            return null;
          }

          return (
            <div
              key={`${lifterDivision.divisionId}-${index}`}
              className="cell-row"
            >
              {getPlace(data, lifterDivision, meet, true) || (
                <span>&nbsp;</span>
              )}
            </div>
          );
        })}
      </div>
    );
  }
};

export default ForecastedPlaceCell;
