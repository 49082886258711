import toNumber from "lodash/toNumber";
import trim from "lodash/trim";
import React, { Component } from "react";
import { updateAttributeOnDocument } from "util/pouchActions";

const KEYCODE_UP = 38;
const KEYCODE_DOWN = 40;
const KEYCODE_RETURN = 13;

type PouchInputProps = {
  meetId: string;
  name: string | string[];
  value: string | number | undefined;
  documentId: string;
  type: "text" | "number";
  beforeSave?: (
    newValue: string | number | undefined
  ) => string | number | undefined;
  style?: React.CSSProperties;
  readOnly?: boolean;
  placeholder?: string;
};

class PouchInput extends Component<
  PouchInputProps,
  { value: string | number | undefined }
> {
  constructor(props: PouchInputProps) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: PouchInputProps) {
    if (
      nextProps.value !== this.props.value ||
      this.props.documentId !== nextProps.documentId
    ) {
      this.setState({ value: nextProps.value });
    }
  }

  // componentDidUpdate(prevProps) {
  //   if (
  //     prevProps.value !== this.props.value ||
  //     this.props.documentId !== prevProps.documentId
  //   ) {
  //     this.setState({ value: this.props.value });
  //   }
  // }

  componentWillUnmount() {
    this.onBlur();
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value: string | number = event.target.value;
    if (value !== "" && this.props.type === "number") {
      value = toNumber(value.replace("-", ""));
    }

    this.setState({ value });
  };

  onBlur = () => {
    let newValue =
      this.props.type === "number"
        ? this.state.value
        : typeof this.state.value === "string"
        ? trim(this.state.value)
        : this.state.value;
    if (
      this.props.value !== newValue &&
      !(this.props.value === undefined && newValue === "")
    ) {
      if (this.props.beforeSave) {
        newValue = this.props.beforeSave(newValue);
      }
      updateAttributeOnDocument(
        this.props.meetId,
        this.props.documentId,
        this.props.name,
        newValue
      );
    }
  };

  onKeyDown = (event: React.KeyboardEvent) => {
    if (this.props.type === "number") {
      if (event.keyCode === KEYCODE_UP || event.keyCode === KEYCODE_DOWN) {
        event.preventDefault();
      }
    }
    if (event.keyCode === KEYCODE_RETURN) {
      this.onBlur();
    }
    event.stopPropagation();
  };

  render() {
    const additionalProps: React.InputHTMLAttributes<HTMLInputElement> = {};
    if (this.props.type === "number") {
      additionalProps.step = "any";
      additionalProps.min = 0;
    }

    return (
      <input
        style={{ width: "100%", ...this.props.style }}
        type={this.props.type}
        value={this.state.value === undefined ? "" : this.state.value}
        onChange={this.onChange}
        onBlur={this.onBlur}
        readOnly={this.props.readOnly}
        placeholder={this.props.placeholder}
        onKeyDown={this.onKeyDown}
        {...additionalProps}
      />
    );
  }
}

export default PouchInput;
