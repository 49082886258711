import React from "react";
import Clock from "components/clock/Clock";
import DisplayComponent from "./DisplayComponent";
import { Platform } from "types";

const Timer = ({ platform, id }: { platform: Platform; id: string }) => {
  return (
    <DisplayComponent style={{ top: 12, left: 645 }} id={id}>
      {({ sizeMultiplier }: { sizeMultiplier: number }) => {
        const style = {
          fontSize: 40 * sizeMultiplier,
          paddingLeft: 24 * sizeMultiplier,
          paddingRight: 24 * sizeMultiplier,
          paddingTop: 12 * sizeMultiplier,
          paddingBottom: 12 * sizeMultiplier,
        };

        return <Clock platform={platform} style={style} />;
      }}
    </DisplayComponent>
  );
};

export default Timer;
