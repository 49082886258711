import React from "react";
import { parseISO, differenceInSeconds, format } from "date-fns";
import { getBenchLabel } from "util/meetHelper";
import AttemptsCount from "components/AttemptsCount";
import { useMeet } from "util/useMeet";
import each from "lodash/each";
import isEmpty from "lodash/isEmpty";
import size from "lodash/size";
import sum from "lodash/sum";
import round from "lodash/round";
import min from "lodash/min";
import max from "lodash/max";
import sortBy from "lodash/sortBy";
import first from "lodash/first";
import last from "lodash/last";
import find from "lodash/find";
import get from "lodash/get";
import filter from "lodash/filter";
import uniq from "lodash/uniq";
import map from "lodash/map";
import { useCompleteLiftingOrder } from "util/useCompleteLiftingOrder";

const Stat = ({
  platformId,
  session,
  title,
}: {
  platformId: string;
  session: number;
  title: string;
}) => {
  const meet = useMeet();
  const platform = meet.platforms[platformId];

  const completeLiftingOrder = useCompleteLiftingOrder(platformId);

  const getLiftStats = (times: any) => {
    const allTimes: any = [];
    const rawTimes: any = [];
    const equippedTimes: any = [];
    times = sortBy(times, "timeStamp");

    each(times, (st, index: number) => {
      if (index !== 0) {
        const previousTime = times[index - 1].timeStamp;
        const thisTime = st.timeStamp;
        const turnOverTime = differenceInSeconds(
          parseISO(thisTime),
          parseISO(previousTime)
        );
        allTimes.push(turnOverTime);
        if (st.rawOrEquipped === "RAW") {
          rawTimes.push(turnOverTime);
        } else if (st.rawOrEquipped === "EQUIPPED") {
          equippedTimes.push(turnOverTime);
        }
      }
    });

    let allAverage: any = "";
    let allMin: any = "";
    let allMax: any = "";
    if (!isEmpty(allTimes)) {
      allAverage = round(sum(allTimes) / size(allTimes), 1);
      allMin = round(min(allTimes) || 0, 1);
      allMax = round(max(allTimes) || 0, 1);
    }

    let rawAverage: any = "";
    let rawMin: any = "";
    let rawMax: any = "";
    if (!isEmpty(rawTimes)) {
      rawAverage = round(sum(rawTimes) / size(rawTimes), 1);
      rawMin = round(min(rawTimes) || 0, 1);
      rawMax = round(max(rawTimes) || 0, 1);
    }

    let equippedAverage: any = "";
    let equippedMin: any = "";
    let equippedMax: any = "";
    if (!isEmpty(equippedTimes)) {
      equippedAverage = round(sum(equippedTimes) / size(equippedTimes), 1);
      equippedMin = round(min(equippedTimes) || 0, 1);
      equippedMax = round(max(equippedTimes) || 0, 1);
    }

    let firstLift: any = get(first(times), "timeStamp");
    let lastLift: any = get(last(times), "timeStamp");

    firstLift = firstLift ? format(parseISO(firstLift), "h:mm:ss a") : "";
    lastLift = lastLift ? format(parseISO(lastLift), "h:mm:ss a") : "";

    return {
      firstLift,
      lastLift,
      allAverage,
      allMin,
      allMax,
      rawAverage,
      rawMin,
      rawMax,
      equippedAverage,
      equippedMin,
      equippedMax,
    };
  };

  if (!platform) {
    return null;
  }

  const sessionLiftingOrder = filter(
    completeLiftingOrder,
    (a) => a.lifter.session === session
  );
  const lifterIds = uniq(map(sessionLiftingOrder, (a) => a.lifter._id));
  const numberOfLifters = size(lifterIds);

  const squatTimes: any = [];
  const benchTimes: any = [];
  const deadTimes: any = [];
  each(sessionLiftingOrder, (a) => {
    const attemptChanges = filter(
      get(a, "attempt.changes") as any,
      (c) => c.attribute === "result"
    );
    const sortedAttemptChanges = sortBy(attemptChanges, "timeStamp");
    const firstMarking = first(sortedAttemptChanges);

    const isEquipped = find(a.lifter.divisions, {
      rawOrEquipped: "EQUIPPED",
    });

    const rawOrEquipped = isEquipped ? "EQUIPPED" : "RAW";

    if (firstMarking) {
      if (a.attempt.liftName === "squat") {
        squatTimes.push({
          timeStamp: firstMarking.timeStamp,
          rawOrEquipped: rawOrEquipped,
        });
      } else if (a.attempt.liftName === "bench") {
        benchTimes.push({
          timeStamp: firstMarking.timeStamp,
          rawOrEquipped: rawOrEquipped,
        });
      } else if (a.attempt.liftName === "dead") {
        deadTimes.push({
          timeStamp: firstMarking.timeStamp,
          rawOrEquipped: rawOrEquipped,
        });
      }
    }
  });

  const squatStats = getLiftStats(squatTimes);
  const benchStats = getLiftStats(benchTimes);
  const deadStats = getLiftStats(deadTimes);

  return (
    <div className="stat">
      <div className="title">{title}</div>
      <br />
      <br />
      <div>
        <label>Number of Lifters:</label> {numberOfLifters}
      </div>
      <br />
      <br />
      <div>complete - remaining / total attempts</div>
      <AttemptsCount completeLiftingOrder={sessionLiftingOrder} />
      <br />
      <br />
      <div>
        <label>First Squat Marked:</label> {squatStats.firstLift}
      </div>
      <div>
        <label>Last Squat Marked:</label> {squatStats.lastLift}
      </div>
      <div>
        <label>First {getBenchLabel(meet)} Marked:</label>{" "}
        {benchStats.firstLift}
      </div>
      <div>
        <label>Last Bench Marked:</label> {benchStats.lastLift}
      </div>
      <div>
        <label>First Dead Marked:</label> {deadStats.firstLift}
      </div>
      <div>
        <label>Last Dead Marked:</label> {deadStats.lastLift}
      </div>
      <br />
      <br />
      <div>
        <label>Average Time Between Attempts - All</label>
      </div>
      <div>
        <label>Squats:</label> {squatStats.allAverage}
      </div>
      <div>
        <label>{getBenchLabel(meet)}:</label> {benchStats.allAverage}
      </div>
      <div>
        <label>Deadlift:</label> {deadStats.allAverage}
      </div>
      <br />
      <div>
        <label>Average Time Between Attempts - Raw</label>
      </div>
      <div>
        <label>Squats:</label> {squatStats.rawAverage}
      </div>
      <div>
        <label>{getBenchLabel(meet)}:</label> {benchStats.rawAverage}
      </div>
      <div>
        <label>Deadlift:</label> {deadStats.rawAverage}
      </div>
      <br />
      <div>
        <label>Average Time Between Attempts - Equipped</label>
      </div>
      <div>
        <label>Squats:</label> {squatStats.equippedAverage}
      </div>
      <div>
        <label>{getBenchLabel(meet)}:</label> {benchStats.equippedAverage}
      </div>
      <div>
        <label>Deadlift:</label> {deadStats.equippedAverage}
      </div>
      <br />
      <div>
        <label>Fastest Turn Around</label>
      </div>
      <div>
        <label>Squats:</label> {squatStats.allMin}
      </div>
      <div>
        <label>{getBenchLabel(meet)}:</label> {benchStats.allMin}
      </div>
      <div>
        <label>Deadlift:</label> {deadStats.allMin}
      </div>
      <br />
      <div>
        <label>Slowest Turn Around</label>
      </div>
      <div>
        <label>Squats:</label> {squatStats.allMax}
      </div>
      <div>
        <label>{getBenchLabel(meet)}:</label> {benchStats.allMax}
      </div>
      <div>
        <label>Deadlift:</label> {deadStats.allMax}
      </div>
    </div>
  );
};

export default Stat;
