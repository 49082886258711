import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import map from "lodash/map";
import get from "lodash/get";
import filter from "lodash/filter";
import orderBy from "lodash/orderBy";
import { deleteMeet } from "util/pouchActions";
import { parseDateString } from "util/dateHelper";
import { getMeets, getMeetMetaData } from "selectors";
import { importMeet } from "util/pouchAdapter";
import FileUpload from "components/fileUpload/FileUpload";
import AddIcon from "icons/AddIcon";
import DeleteIcon from "icons/DeleteIcon";
import LogoIcon from "icons/LogoIcon";
import FileIcon from "icons/FileIcon";
import NewMeetModal from "app/newMeetModal/NewMeetModal";
import HomeNavigation from "app/HomeNavigation";
import { FeatureComparison } from "./FeatureComparison";
import OnlineMeets from "components/OnlineMeets";
import RelayServerMeets from "components/RelayServerMeets";

const Meets = () => {
  const meetMetaData = useSelector(getMeetMetaData);
  const meets = useSelector(getMeets);
  const [isNewMeetModalOpen, setIsNewMeetModalOpen] = React.useState(false);
  const [importingMeetFromDataFile, setImportingMeetFromDataFile] =
    React.useState(false);
  const [importingMeetFromDataFileId, setImportingMeetFromDataFileId] =
    React.useState("");
  const [scrolled, setScrolled] = React.useState(false);

  const onScroll = React.useCallback(() => {
    const position = document
      .getElementById("waypoint")
      ?.getBoundingClientRect()?.top;
    if (position && position < 45) {
      setScrolled(true);
    } else {
      if (scrolled) {
        setScrolled(false);
      }
    }
  }, [scrolled]);

  React.useEffect(() => {
    document.addEventListener("scroll", onScroll);
    return () => document.removeEventListener("scroll", onScroll);
  }, [onScroll]);

  const deleteMeetConfirm = (meet: { _id: string; name?: string }) => {
    // TODO: Move this to the meet details page.
    const isOnline = meetMetaData[meet._id].isOnline;
    const message = !isOnline
      ? `Are you sure you want to delete ${meet.name}? Data loss will be permanent`
      : `Are you sure you want to delete ${meet.name}? This will only delete the local copy. The meet will still be available online. Contact support to delete from online database.`;
    if (window.confirm(message)) {
      deleteMeet(meet);
    }
  };

  const openNewMeetModal = () => {
    setIsNewMeetModalOpen(true);
  };

  const closeNewMeetModal = () => {
    setIsNewMeetModalOpen(false);
  };

  const importMeetFromDataFile = (docString: string) => {
    setImportingMeetFromDataFile(true);
    const importedMeetId = importMeet(docString);
    if (importedMeetId) {
      setImportingMeetFromDataFileId(importedMeetId);
    } else {
      // something went wrong
      setImportingMeetFromDataFile(false);
    }
  };

  const localMeets = filter(meets, (meet) => {
    return get(meetMetaData, [meet._id, "isLocal"], false);
  });

  const sortedLocalMeets = orderBy(
    localMeets,
    [
      (m) => parseDateString({ dateString: m.date, meet: m }),
      (m) => m.name?.toLowerCase(),
    ],
    ["desc", "asc"]
  );

  return (
    <div className="meets">
      <HomeNavigation scrolled={scrolled} />
      <div className="logo-wrapper">
        <LogoIcon className="logo animate" />
      </div>
      <div id="waypoint" />

      {process.env.RELAY_SERVER ? <RelayServerMeets /> : <OnlineMeets />}
      <div className="meets-table-table-wrapper">
        <table className="table">
          <thead>
            <tr>
              <td colSpan={3}>
                <a href="#your-meets" id="your-meets">
                  Your Meets (Available Offline)
                </a>
              </td>
            </tr>
            <tr>
              <th style={{ width: "65%" }}>Name</th>
              <th style={{ width: "25%", minWidth: 110 }}>Date</th>
              <th style={{ width: "8%", minWidth: 55 }}></th>
            </tr>
          </thead>
          <tbody>
            {map(sortedLocalMeets, (meet) => {
              return (
                <tr key={meet._id}>
                  <td>
                    <Link to={`/meets/${meet._id}/setup`}>
                      {get(meet, "name")}
                    </Link>
                  </td>
                  <td>{get(meet, "date")}</td>
                  <td>
                    <button onClick={() => deleteMeetConfirm(meet)}>
                      <DeleteIcon />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {!process.env.RELAY_SERVER && (
        <>
          <button className="new-meet-button" onClick={openNewMeetModal}>
            <AddIcon />
            &nbsp;Create New Meet
          </button>
          <NewMeetModal
            isOpen={isNewMeetModalOpen}
            onRequestClose={closeNewMeetModal}
          />

          {importingMeetFromDataFile &&
          !localMeets.find((m) => m._id === importingMeetFromDataFileId) ? (
            <button style={{ marginTop: 20 }} disabled>
              Loading data file...
            </button>
          ) : (
            <FileUpload
              accept="json"
              buttonText={
                <span>
                  <FileIcon />
                  &nbsp;Upload Meet From Data File
                </span>
              }
              onChange={importMeetFromDataFile}
            />
          )}
        </>
      )}

      <div className="about-us" style={{ textAlign: "center" }}>
        LiftingCast is Powerlifting meet management software. LiftingCast can be
        used offline or online.
      </div>

      <div className="about-link">
        <Link to="/about">More Info - FAQ</Link>
      </div>

      <div className="about-link">
        <a href="/changes.html">Changelog</a>
      </div>

      <div className="about-link">
        <Link to="/instructions">Meet Director Instructions</Link>
      </div>

      <div className="about-link">
        <Link to="/coach">Instructions for coaches</Link>
      </div>

      <div className="about-link" style={{ marginBottom: 80 }}>
        <Link to="/contact">Contact</Link>
      </div>

      <FeatureComparison />

      <div className="version-info">Version 2.1.0</div>
    </div>
  );
};

export default Meets;
