import React from "react";
import { getLifterAwardsWeightClassDoc } from "util/lifterHelper";
import FixedHeightWrapper from "app/meets/FixedHeightWrapper";
import ResultsTable from "./ResultsTable";
import "./ResultsIndex.scss";
import { useMeet } from "util/useMeet";
import find from "lodash/find";
import map from "lodash/map";
import filter from "lodash/filter";
import { Division, WeightClass } from "types";

const ResultsIndex = () => {
  const meet = useMeet();

  const divisions = filter(meet.divisions, (division) => {
    return !!find(meet.lifters, (lifter) => {
      return !!find(lifter.divisions, { divisionId: division._id });
    });
  });

  const divisionsAndWeightClasses: (Omit<Division, "weightClasses"> & {
    weightClasses: WeightClass[];
  })[] = map(divisions, (division) => {
    const weightClasses = filter(division.weightClasses, (wc) => {
      return !!find(meet.lifters, (lifter) => {
        return !!find(lifter.divisions, (ld) => {
          const weightClassDoc = getLifterAwardsWeightClassDoc(
            meet,
            lifter,
            ld
          );
          return "_id" in weightClassDoc && weightClassDoc._id === wc._id;
        });
      });
    });
    return { ...division, weightClasses };
  });

  return (
    <FixedHeightWrapper>
      <div className="results-index">
        <ResultsTable
          meet={meet}
          divisions={divisionsAndWeightClasses}
          showButtons
        />
      </div>
    </FixedHeightWrapper>
  );
};

export default ResultsIndex;
