import Papa from "papaparse";
import kebabCase from "lodash/kebabCase";
import each from "lodash/each";
import capitalize from "lodash/capitalize";
import download from "downloadjs";
import { format } from "date-fns";

import { getTotal, getRealAge } from "util/lifterHelper";
import { parseDateString } from "util/dateHelper";
import { getMeetUnits } from "util/meetHelper";

import { getAttemptDisplay, getBestAttemptDisplay } from "util/exportData";
import { Division, Gender, Meet } from "types";
import groupBy from "lodash/groupBy";

const presentStringAsDownload = function (
  csvString: string,
  meet: Meet,
  type: string
) {
  let fileName = `${type}.csv`;
  fileName = kebabCase(meet.name) + fileName;

  download(
    new Blob([csvString], { type: "text/csv;charset=utf-8" }),
    fileName,
    "text/csv"
  );
};

export const openPowerliftingCompetitionCode = function (division: Division) {
  let compEvents = "";
  const isSquatting = division.lifts.squat;
  const isBenching = division.lifts.bench;
  const isDeadlifting = division.lifts.dead;
  if (isSquatting && isBenching && isDeadlifting) {
    compEvents = "SBD";
  } else if (isBenching && isDeadlifting) {
    compEvents = "BD";
  } else if (isSquatting && isBenching) {
    compEvents = "SB";
  } else if (isBenching) {
    compEvents = "B";
  } else if (isDeadlifting) {
    compEvents = "D";
  } else if (isSquatting) {
    compEvents = "S";
  }

  return compEvents;
};

export const exportOpenPowerliftingResults = function ({
  meet,
  dataArray,
  meetCountry,
  meetState,
  meetTown,
}: {
  meet: Meet;
  dataArray: any;
  meetCountry: string;
  meetState: string;
  meetTown: string;
}) {
  const csvObject = [];

  let formula = "";
  const formulaGroups = groupBy(meet.divisions, "scoreBy");
  const formulas = Object.keys(formulaGroups).filter((f) => f !== "TOTAL");
  const mappedFormulas = formulas.map((f) => {
    return f.replaceAll("BODY_WEIGHT", "WILKS").replaceAll("_POINTS", "");
  });
  if (mappedFormulas.length) {
    formula = mappedFormulas.join(", ");
  }

  const genderCodes = {
    MALE: "M",
    FEMALE: "F",
    MX: "MX",
  };

  each(dataArray, (lifter) => {
    if (!lifter || lifter.row === "title" || lifter.row === "header") {
      return;
    }

    const division = lifter.division;
    const weightClass = lifter.weightClass;
    const place = lifter.place ? lifter.place : "DQ";

    const gender = lifter.gender as Gender;
    let oplGender = genderCodes[gender];

    if (meet.federation === "PLU") {
      if (oplGender === "MX") {
        oplGender = "N";
      }
    }

    const compCode = openPowerliftingCompetitionCode(division);
    const parsedBirthDate = parseDateString({
      dateString: lifter.birthDate,
      meet,
    });
    const birthDate = parsedBirthDate ? format(parsedBirthDate, "yyyy") : "";

    const row = {
      place: place,
      name: lifter.name,
      birthYear: birthDate,
      gender: meet.federation === "PLU" && oplGender === "MX" ? "N" : oplGender,
      age: getRealAge(lifter, meet),
      country: lifter.country || "",
      state: lifter.state,
      rawOrEquipped: capitalize(division.rawOrEquipped),
      awardsDivision: division.name,
      bodyWeight: lifter.bodyWeight,
      weightClass: weightClass.name,
      squat1: getAttemptDisplay(lifter, "squat", "1") || "",
      squat2: getAttemptDisplay(lifter, "squat", "2") || "",
      squat3: getAttemptDisplay(lifter, "squat", "3") || "",
      bestSquat: getBestAttemptDisplay(lifter, "squat") || "",
      squat4: getAttemptDisplay(lifter, "squat", "4") || "",
      bench1: getAttemptDisplay(lifter, "bench", "1") || "",
      bench2: getAttemptDisplay(lifter, "bench", "2") || "",
      bench3: getAttemptDisplay(lifter, "bench", "3") || "",
      bestBench: getBestAttemptDisplay(lifter, "bench") || "",
      bench4: getAttemptDisplay(lifter, "bench", "4") || "",
      dead1: getAttemptDisplay(lifter, "dead", "1") || "",
      dead2: getAttemptDisplay(lifter, "dead", "2") || "",
      dead3: getAttemptDisplay(lifter, "dead", "3") || "",
      dead4: getAttemptDisplay(lifter, "dead", "4") || "",
      bestDead: getBestAttemptDisplay(lifter, "dead") || "",
      total: getTotal(lifter, division._id, meet) || "",
      event: compCode,
    };

    csvObject.push(row);
  });

  const meetUnits = capitalize(getMeetUnits(meet));

  let header: Record<string, string | undefined> = {
    place: "Place",
    name: "Name",
    birthDate: "BirthDate",
    gender: "Sex",
    birthYear: "BirthYear",
    age: "Age",
    country: "Country",
    state: "State",
    rawOrEquipped: "Equipment",
    awardsDivision: "Division",
    bodyWeight: `Bodyweight${meetUnits}`,
    weightClass: `WeightClass${meetUnits}`,
    squat1: `Squat1${meetUnits}`,
    squat2: `Squat2${meetUnits}`,
    squat3: `Squat3${meetUnits}`,
    bestSquat: `Best3Squat${meetUnits}`,
    squat4: `Squat4${meetUnits}`,
    bench1: `Bench1${meetUnits}`,
    bench2: `Bench2${meetUnits}`,
    bench3: `Bench3${meetUnits}`,
    bestBench: `Best3Bench${meetUnits}`,
    bench4: `Bench4${meetUnits}`,
    dead1: `Deadlift1${meetUnits}`,
    dead2: `Deadlift2${meetUnits}`,
    dead3: `Deadlift3${meetUnits}`,
    bestDead: `Best3Deadlift${meetUnits}`,
    dead4: `Deadlift4${meetUnits}`,
    total: `Total${meetUnits}`,
    event: "Event",
  };
  csvObject.unshift(header);

  csvObject.unshift({});

  const parsedMeetDate = parseDateString({ dateString: meet.date, meet });
  const formattedMeetDate = parsedMeetDate
    ? format(parsedMeetDate, "yyyy-MM-dd")
    : "";

  header = {
    place: meet.federation,
    name: formattedMeetDate,
    birthDate: meetCountry,
    gender: meetState,
    birthYear: meetTown,
    age: meet.name,
    country: formula,
  };
  csvObject.unshift(header);

  header = {
    place: "Federation",
    name: "Date",
    birthDate: "MeetCountry",
    gender: "MeetState",
    birthYear: "MeetTown",
    age: "MeetName",
    country: "Formula",
  };
  csvObject.unshift(header);

  header = {};
  csvObject.unshift(header);

  // The top line must have all the columns.
  header = {
    place: "OPL Format v1",
    name: "Submit by email:",
    birthDate: "issues@openpowerlifting.org",
    gender: "",
    birthYear: "",
    age: "",
    country: "",
    state: "",
    rawOrEquipped: "",
    awardsDivision: "",
    bodyWeight: "",
    weightClass: "",
    squat1: "",
    squat2: "",
    squat3: "",
    bestSquat: "",
    squat4: "",
    bench1: "",
    bench2: "",
    bench3: "",
    bestBench: "",
    bench4: "",
    dead1: "",
    dead2: "",
    dead3: "",
    bestDead: "",
    dead4: "",
    total: "",
    event: "",
  };

  csvObject.unshift(header);

  const csvString = Papa.unparse(csvObject, { quotes: true, header: false });

  presentStringAsDownload(csvString, meet, ".opl");
};
