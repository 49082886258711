import React from "react";
import Lights, { RefLight } from "components/lights/Lights";
import "./JuryIndex.scss";
import { useMeet } from "util/useMeet";
import { usePlatform } from "util/usePlatform";
import { useCurrentAttempt } from "util/useCurrentAttempt";
import { useCompleteLiftingOrder } from "util/useCompleteLiftingOrder";
import findIndex from "lodash/findIndex";
import max from "lodash/max";
import min from "lodash/min";
import ThumbsUpIcon from "icons/ThumbsUpIcon";
import ThumbsDownIcon from "icons/ThumbsDownIcon";
import capitalize from "lodash/capitalize";
import map from "lodash/map";
import { Attempt, Lifter, Meet, Platform, RefCard } from "types";
import { updateAttributesOnDocument } from "util/pouchActions";
import BoardIndex from "../board/BoardIndex";

const AttemptInfo = ({
  attempt,
  lifter,
  meet,
}: {
  attempt: Attempt;
  lifter: Lifter;
  meet: Meet;
}) => {
  return (
    <div className="jury-lift-info">
      {lifter.name} - {capitalize(attempt.liftName)} {attempt.attemptNumber} -{" "}
      {attempt.weight} {meet.units}
    </div>
  );
};

const CurrentAttempt = ({
  attempt,
  lifter,
  meet,
  platform,
}: {
  attempt: Attempt;
  lifter: Lifter;
  meet: Meet;
  platform: Platform;
}) => {
  return (
    <div className="jury-attempt current">
      <div className="jury-lift-info">Current Lifter</div>
      <AttemptInfo attempt={attempt} lifter={lifter} meet={meet} />
      <Lights meet={meet} platformId={platform._id} showChecks />
    </div>
  );
};

const OverriddenAttempt = ({
  attempt,
  lifter,
  meet,
}: {
  attempt: Attempt;
  lifter: Lifter;
  meet: Meet;
}) => {
  const undoJuryOverturn = (attempt: Attempt) => {
    const changes = {
      result: attempt.result === "good" ? "bad" : "good",
      decisions: attempt.preJuryOverturnDecisions,
      juryOverride: null,
      juryOverturnCard: null,
      preJuryOverturnDecisions: null,
    };
    updateAttributesOnDocument(meet._id, attempt._id, changes);
  };

  return (
    <div className="jury-attempt">
      <AttemptInfo attempt={attempt} lifter={lifter} meet={meet} />
      {!attempt.preJuryOverturnDecisions && (
        <div
          className="jury-lift-info"
          style={{ maxWidth: 100, margin: "auto" }}
        >
          <div className="crossed-out"></div>
          {attempt.result === "good" ? "No" : "Good"} Lift
        </div>
      )}
      {!!attempt.preJuryOverturnDecisions && (
        <div className="lights jury-override-lights">
          <div className="crossed-out"></div>
          <RefLight
            refDecision={attempt.preJuryOverturnDecisions.left}
            showDecisions
            showChecks
          />
          <RefLight
            refDecision={attempt.preJuryOverturnDecisions.head}
            showDecisions
            showChecks
          />
          <RefLight
            refDecision={attempt.preJuryOverturnDecisions.right}
            showDecisions
            showChecks
          />
        </div>
      )}
      <div className="jury-lift-info" style={{ marginTop: 12 }}>
        Overturned{" "}
        {attempt.result === "good" && <ThumbsUpIcon className="good" />}
        {attempt.result === "bad" && <ThumbsDownIcon className="bad" />}
        <button
          style={{ marginLeft: 8 }}
          onClick={() => undoJuryOverturn(attempt)}
        >
          UNDO
        </button>
      </div>
    </div>
  );
};

const RegularAttempt = ({
  attempt,
  lifter,
  meet,
  platform,
  isLastAttempt,
}: {
  attempt: Attempt;
  lifter: Lifter;
  meet: Meet;
  platform: Platform;
  isLastAttempt: boolean;
}) => {
  const overturnToGood = (attempt: Attempt) => {
    updateAttributesOnDocument(meet._id, attempt._id, {
      juryOverride: true,
      result: "good",
      decisions: null,
      preJuryOverturnDecisions: attempt.decisions,
    });
  };

  const overturnToBad = (attempt: Attempt, card: RefCard) => {
    updateAttributesOnDocument(meet._id, attempt._id, {
      juryOverride: true,
      result: "bad",
      decisions: null,
      juryOverturnCard: card,
      preJuryOverturnDecisions: attempt.decisions,
    });
  };

  return (
    <div className="jury-attempt">
      {isLastAttempt && <div className="jury-lift-info">Next Lifter</div>}
      <AttemptInfo attempt={attempt} lifter={lifter} meet={meet} />

      {!!attempt.decisions ? (
        <div className="lights">
          <RefLight
            refDecision={attempt.decisions.left}
            showDecisions
            showChecks
          />
          <RefLight
            refDecision={attempt.decisions.head}
            showDecisions
            showChecks
          />
          <RefLight
            refDecision={attempt.decisions.right}
            showDecisions
            showChecks
          />
        </div>
      ) : (
        <>
          {!!attempt.result && (
            <div className="jury-lift-info">
              {attempt.result === "good" ? "Good" : "No"}
              Lift
            </div>
          )}
        </>
      )}

      {attempt.result === "bad" && (
        <div className="jury-button-row">
          <button onClick={() => overturnToGood(attempt)}>
            Overturn <ThumbsUpIcon />
          </button>
        </div>
      )}
      {attempt.result === "good" && (
        <div className="jury-button-row">
          <button className="red" onClick={() => overturnToBad(attempt, "red")}>
            Overturn <ThumbsDownIcon />
          </button>
          <button
            className="blue"
            onClick={() => overturnToBad(attempt, "blue")}
          >
            Overturn <ThumbsDownIcon />
          </button>
          <button
            className="yellow"
            onClick={() => overturnToBad(attempt, "yellow")}
          >
            Overturn <ThumbsDownIcon />
          </button>
        </div>
      )}
    </div>
  );
};

const JuryIndex = () => {
  const meet = useMeet();
  const platform = usePlatform();
  const currentAttempt = useCurrentAttempt();
  const completeLiftingOrder = useCompleteLiftingOrder();
  const currentAttemptPosition = findIndex(
    completeLiftingOrder,
    (row) => row.attemptId === platform.currentAttemptId
  );

  const narrowedLiftingOrder = completeLiftingOrder.slice(
    max([currentAttemptPosition - 3, 0]),
    min([currentAttemptPosition + 2, completeLiftingOrder.length])
  );

  return (
    <div className="jury">
      <div className="jury-attempt-list">
        {map(narrowedLiftingOrder, (row, index) => {
          const isCurrentAttempt = row.attemptId === currentAttempt._id;
          const isLastAttempt = index === narrowedLiftingOrder.length - 1;
          const isOverridden = !!row.attempt.juryOverride;
          if (isCurrentAttempt) {
            return (
              <CurrentAttempt
                key={row.attempt._id}
                attempt={row.attempt}
                lifter={row.lifter}
                meet={meet}
                platform={platform}
              />
            );
          }

          if (isOverridden) {
            return (
              <OverriddenAttempt
                key={row.attempt._id}
                attempt={row.attempt}
                lifter={row.lifter}
                meet={meet}
              />
            );
          }

          return (
            <RegularAttempt
              key={row.attempt._id}
              attempt={row.attempt}
              lifter={row.lifter}
              meet={meet}
              platform={platform}
              isLastAttempt={isLastAttempt}
            />
          );
        })}
      </div>
      <BoardIndex />
    </div>
  );
};

export default JuryIndex;
