import React from "react";

import LightsComponent from "components/lights/Lights";

import DisplayComponent from "./DisplayComponent";
import { Meet } from "types";

const Lights = ({
  platformId,
  meet,
  id,
}: {
  platformId: string;
  meet: Meet;
  id: string;
}) => {
  return (
    <DisplayComponent style={{ top: 90, left: 580 }} id={id}>
      {({ sizeMultiplier }: { sizeMultiplier: number }) => {
        const style = {
          height: 80 * sizeMultiplier,
        };

        return (
          <LightsComponent meet={meet} platformId={platformId} style={style} />
        );
      }}
    </DisplayComponent>
  );
};

export default Lights;
