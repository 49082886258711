import React from "react";
import { Link } from "react-router-dom";
import kebabCase from "lodash/kebabCase";
import map from "lodash/map";
import get from "lodash/get";
import filter from "lodash/filter";
import orderBy from "lodash/orderBy";
import classNames from "classnames";
import { reformatDateToUsFormat } from "util/dateHelper";
import { isAfter, endOfYesterday } from "date-fns";
import { fetchWrapper } from "util/api";
import { parseDateString } from "util/dateHelper";

const OnlineMeetsTable = ({
  title,
  meets,
  isLoadingMeets,
  isLoadingMeetsFailure,
}: {
  title: string;
  meets: {
    name: string;
    showOnHomePage: boolean;
    _id: string;
    date: string;
    dateFormat: string;
  }[];
  isLoadingMeets: boolean;
  isLoadingMeetsFailure: boolean;
}) => {
  const [searchQuery, setSearchQuery] = React.useState("");
  const [collapsedSetting, setCollapsedSetting] = React.useState(true);

  const anchor = kebabCase(title);

  const filteredMeets = meets.filter(
    (m) => m.name && m.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const collapsed =
    collapsedSetting &&
    !isLoadingMeetsFailure &&
    (isLoadingMeets || filteredMeets.length > 20);

  return (
    <div>
      <div
        className={classNames(
          "meets-table-table-wrapper",
          collapsed && "meets-table-collapsed"
        )}
      >
        <table className="table">
          <thead>
            <tr>
              <td colSpan={3}>
                <div className="table-title">
                  <a href={`#${anchor}`} id={anchor}>
                    {title}
                  </a>
                  <input
                    placeholder="Search..."
                    type="text"
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                      setCollapsedSetting(false);
                    }}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th style={{ width: "70%" }}>Name</th>
              <th style={{ width: "30%" }}>Date</th>
            </tr>
          </thead>
          <tbody>
            {!isLoadingMeets &&
              !isLoadingMeetsFailure &&
              map(filteredMeets, (meet) => {
                if (!meet.showOnHomePage) {
                  return null;
                }

                return (
                  <tr key={meet._id}>
                    <td>
                      <Link to={`/meets/${meet._id}/results`}>
                        {get(meet, "name")}
                      </Link>
                    </td>
                    <td>
                      {reformatDateToUsFormat({
                        dateString: meet.date,
                        meet,
                      })}
                    </td>
                  </tr>
                );
              })}
            {isLoadingMeets && (
              <tr>
                <td colSpan={2}>Loading...</td>
              </tr>
            )}
            {isLoadingMeetsFailure && (
              <tr>
                <td colSpan={2}>Failed to load meet data!</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {collapsed && (
        <div className="meet-table-show-more">
          <button onClick={() => setCollapsedSetting(false)}>Show More</button>
        </div>
      )}
    </div>
  );
};

const OnlineMeets = () => {
  const [onlineMeets, setOnlineMeets] = React.useState<
    {
      showOnHomePage: boolean;
      name: string;
      date: string;
      dateFormat: string;
      createDate: string;
      _id: string;
    }[]
  >([]);
  const [isLoadingMeets, setIsLoadingMeets] = React.useState(false);
  const [isLoadingMeetsFailure, setIsLoadingMeetsFailure] =
    React.useState(false);

  const getAllMeets = React.useCallback(async () => {
    setIsLoadingMeets(true);
    try {
      const response = await fetchWrapper("/api/meets", "GET");
      if (response?.docs) {
        setOnlineMeets(get(response, "docs", []));
      } else {
        setIsLoadingMeetsFailure(true);
      }
      setIsLoadingMeets(false);
    } catch (e) {
      setIsLoadingMeetsFailure(true);
      setIsLoadingMeets(false);
    }
  }, []);

  React.useEffect(() => {
    getAllMeets();
  }, [getAllMeets]);

  const visibleOnlineMeets = filter(onlineMeets, (m) => m.showOnHomePage);

  const upcomingMeets = filter(visibleOnlineMeets, (m) =>
    isAfter(
      parseDateString({ dateString: m.date, meet: m }) ?? new Date(),
      endOfYesterday()
    )
  );
  const sortedUpcomingMeets = orderBy(
    upcomingMeets,
    [
      (m) => parseDateString({ dateString: m.date, meet: m }),
      (m) => m.name?.toLowerCase(),
    ],
    ["asc", "asc"]
  );

  const pastMeets = filter(
    visibleOnlineMeets,
    (m) =>
      !isAfter(
        parseDateString({ dateString: m.date, meet: m }) ?? new Date(),
        endOfYesterday()
      )
  );
  const sortedPastMeets = orderBy(
    pastMeets,
    [
      (m) => parseDateString({ dateString: m.date, meet: m }),
      (m) => m.name?.toLowerCase(),
    ],
    ["desc", "asc"]
  );

  return (
    <>
      {" "}
      <OnlineMeetsTable
        meets={sortedUpcomingMeets}
        title="Upcoming Meets"
        isLoadingMeets={isLoadingMeets}
        isLoadingMeetsFailure={isLoadingMeetsFailure}
      />
      <OnlineMeetsTable
        meets={sortedPastMeets}
        title="Recent Meets"
        isLoadingMeets={isLoadingMeets}
        isLoadingMeetsFailure={isLoadingMeetsFailure}
      />
    </>
  );
};

export default OnlineMeets;
