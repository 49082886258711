import each from "lodash/each";
import get from "lodash/get";
import capitalize from "lodash/capitalize";
import { format } from "date-fns";

import { parseDateString } from "util/dateHelper";
import { getTotal, getRealAge } from "util/lifterHelper";

import { getMeetUnits } from "util/meetHelper";

import { getAttemptDisplay, getBestAttemptDisplay } from "util/exportData";
import {
  AttemptNumber,
  CsvArray,
  Division,
  EquipmentLevel,
  FederationDivisionsConfig,
  Gender,
  LiftName,
  Lifter,
  Meet,
} from "types";

// prettier-ignore
const maleWeightClasses = [
  {name: '52',   lbsName: '114.64lbs (52kg)',    maxWeight: 52,   lbsMaxWeight: 114.64},
  {name: '56',   lbsName: '123.46lbs (56kg)',    maxWeight: 56,   lbsMaxWeight: 123.46},
  {name: '60',   lbsName: '132.28lbs (60kg)',    maxWeight: 60,   lbsMaxWeight: 132.28},
  {name: '67.5', lbsName: '148.81lbs (67.5kg)',  maxWeight: 67.5, lbsMaxWeight: 148.81},
  {name: '75',   lbsName: '165.35lbs (75kg)',    maxWeight: 75,   lbsMaxWeight: 165.35},
  {name: '82.5', lbsName: '181.88lbs (82.5kg)',  maxWeight: 82.5, lbsMaxWeight: 181.88},
  {name: '90',   lbsName: '198.42lbs (90kg)',    maxWeight: 90,   lbsMaxWeight: 198.42},
  {name: '100',  lbsName: '220.46lbs (100kg)',   maxWeight: 100,  lbsMaxWeight: 220.46},
  {name: '110',  lbsName: '242.51lbs (110kg)',   maxWeight: 110,  lbsMaxWeight: 242.51},
  {name: '125',  lbsName: '275.58lbs (125kg)',   maxWeight: 125,  lbsMaxWeight: 275.58},
  {name: '140',  lbsName: '308.65lbs (140kg)',   maxWeight: 140,  lbsMaxWeight: 308.65},
  {name: '140+', lbsName: '308.65lbs+ (140kg+)', maxWeight: 9999, lbsMaxWeight: 9999}
];

// prettier-ignore
const femaleWeightClasses = [
  {name: '44',   lbsName: '97.00lbs (44kg)',    maxWeight: 44,   lbsMaxWeight: 97.00},
  {name: '48',   lbsName: '105.82lbs (48kg)',   maxWeight: 48,   lbsMaxWeight: 105.82},
  {name: '52',   lbsName: '114.64lbs (52kg)',   maxWeight: 52,   lbsMaxWeight: 114.64},
  {name: '56',   lbsName: '123.46lbs (56kg)',   maxWeight: 56,   lbsMaxWeight: 123.46},
  {name: '60',   lbsName: '132.28lbs (60kg)',   maxWeight: 60,   lbsMaxWeight: 132.28},
  {name: '67.5', lbsName: '148.81lbs (67.5kg)', maxWeight: 67.5, lbsMaxWeight: 148.81},
  {name: '75',   lbsName: '165.35lbs (75kg)',   maxWeight: 75,   lbsMaxWeight: 165.35},
  {name: '82.5', lbsName: '181.88lbs (82.5kg)', maxWeight: 82.5, lbsMaxWeight: 181.88},
  {name: '90',   lbsName: '198.42lbs (90kg)',   maxWeight: 90,   lbsMaxWeight: 198.42},
  {name: '100',  lbsName: '220.46lbs (100kg)',   maxWeight: 100,  lbsMaxWeight: 220.46},
  {name: '110',  lbsName: '242.51lbs (110kg)',   maxWeight: 110,  lbsMaxWeight: 242.51},
  {name: '125',  lbsName: '275.58lbs (125kg)',   maxWeight: 125,  lbsMaxWeight: 275.58},
  {name: '140',  lbsName: '308.65lbs (140kg)',   maxWeight: 140,  lbsMaxWeight: 308.65},
  {name: '140+', lbsName: '308.65lbs+ (140kg+)', maxWeight: 9999, lbsMaxWeight: 9999}
];

const pluWeightClasses = {
  MALE: maleWeightClasses,
  FEMALE: femaleWeightClasses,
  MX: femaleWeightClasses,
};

// prettier-ignore
export const pluBaseDivisions: FederationDivisionsConfig = [
  {name: "Drug Tested Junior (10-12)",     code: 'Y1',  low: 10,  high: 12,   default: true,  records: true,  weightClasses: pluWeightClasses},
  {name: "Drug Tested Junior (13-15)",     code: 'J1',  low: 13,  high: 15,   default: true,  records: true,  weightClasses: pluWeightClasses},
  {name: "Drug Tested Junior (16-17)",     code: 'J2',  low: 16,  high: 17,   default: true,  records: true,  weightClasses: pluWeightClasses},
  {name: "Drug Tested Junior (18-19)",     code: 'J3',  low: 18,  high: 19,   default: true,  records: true,  weightClasses: pluWeightClasses},
  {name: "Drug Tested Junior (20-23)",     code: 'J4',  low: 20,  high: 23,   default: true,  records: true,  weightClasses: pluWeightClasses},
  {name: "Drug Tested Open",               code: 'O',   low: 24,  high: 39,   default: true,  records: true,  weightClasses: pluWeightClasses},
  {name: "Drug Tested Sub-Master (35-39)", code: 'SM',  low: 35,  high: 39,   default: true,  records: true,  weightClasses: pluWeightClasses},
  {name: "Drug Tested Master (40-44)",     code: 'M1',  low: 40,  high: 44,   default: true,  records: true,  weightClasses: pluWeightClasses},
  {name: "Drug Tested Master (45-49)",     code: 'M2',  low: 45,  high: 49,   default: true,  records: true,  weightClasses: pluWeightClasses},
  {name: "Drug Tested Master (50-54)",     code: 'M3',  low: 50,  high: 54,   default: true,  records: true,  weightClasses: pluWeightClasses},
  {name: "Drug Tested Master (55-59)",     code: 'M4',  low: 55,  high: 59,   default: true,  records: true,  weightClasses: pluWeightClasses},
  {name: "Drug Tested Master (60-64)",     code: 'M5',  low: 60,  high: 64,   default: true,  records: true,  weightClasses: pluWeightClasses},
  {name: "Drug Tested Master (65-69)",     code: 'M6',  low: 65,  high: 69,   default: true,  records: true,  weightClasses: pluWeightClasses},
  {name: "Drug Tested Master (70-74)",     code: 'M7',  low: 70,  high: 74,   default: true,  records: true,  weightClasses: pluWeightClasses},
  {name: "Drug Tested Master (75-79)",     code: 'M8',  low: 75,  high: 79,   default: true,  records: true,  weightClasses: pluWeightClasses},
  {name: "Drug Tested Master (80+)",       code: 'M9',  low: 80,  high: 999,  default: true,  records: true,  weightClasses: pluWeightClasses},

  {name: "Untested Junior (10-12)",     code: 'Y1',  low: 10,  high: 12,   default: true,  records: true,  weightClasses: pluWeightClasses},
  {name: "Untested Junior (13-15)",     code: 'J1',  low: 13,  high: 15,   default: true,  records: true,  weightClasses: pluWeightClasses},
  {name: "Untested Junior (16-17)",     code: 'J2',  low: 16,  high: 17,   default: true,  records: true,  weightClasses: pluWeightClasses},
  {name: "Untested Junior (18-19)",     code: 'J3',  low: 18,  high: 19,   default: true,  records: true,  weightClasses: pluWeightClasses},
  {name: "Untested Junior (20-23)",     code: 'J4',  low: 20,  high: 23,   default: true,  records: true,  weightClasses: pluWeightClasses},
  {name: "Untested Open",               code: 'O',   low: 24,  high: 39,   default: true,  records: true,  weightClasses: pluWeightClasses},
  {name: "Untested Sub-Master (35-39)", code: 'SM',  low: 35,  high: 39,   default: true,  records: true,  weightClasses: pluWeightClasses},
  {name: "Untested Master (40-44)",     code: 'M1',  low: 40,  high: 44,   default: true,  records: true,  weightClasses: pluWeightClasses},
  {name: "Untested Master (45-49)",     code: 'M2',  low: 45,  high: 49,   default: true,  records: true,  weightClasses: pluWeightClasses},
  {name: "Untested Master (50-54)",     code: 'M3',  low: 50,  high: 54,   default: true,  records: true,  weightClasses: pluWeightClasses},
  {name: "Untested Master (55-59)",     code: 'M4',  low: 55,  high: 59,   default: true,  records: true,  weightClasses: pluWeightClasses},
  {name: "Untested Master (60-64)",     code: 'M5',  low: 60,  high: 64,   default: true,  records: true,  weightClasses: pluWeightClasses},
  {name: "Untested Master (65-69)",     code: 'M6',  low: 65,  high: 69,   default: true,  records: true,  weightClasses: pluWeightClasses},
  {name: "Untested Master (70-74)",     code: 'M7',  low: 70,  high: 74,   default: true,  records: true,  weightClasses: pluWeightClasses},
  {name: "Untested Master (75-79)",     code: 'M8',  low: 75,  high: 79,   default: true,  records: true,  weightClasses: pluWeightClasses},
  {name: "Untested Master (80+)",       code: 'M9',  low: 80,  high: 999,  default: true,  records: true,  weightClasses: pluWeightClasses},


  {name: "Parasports Drug Tested Junior (10-12)",     code: 'Y1',  low: 10,  high: 12,   default: false,  records: false,  weightClasses: pluWeightClasses},
  {name: "Parasports Drug Tested Junior (13-15)",     code: 'J1',  low: 13,  high: 15,   default: false,  records: false,  weightClasses: pluWeightClasses},
  {name: "Parasports Drug Tested Junior (16-17)",     code: 'J2',  low: 16,  high: 17,   default: false,  records: false,  weightClasses: pluWeightClasses},
  {name: "Parasports Drug Tested Junior (18-19)",     code: 'J3',  low: 18,  high: 19,   default: false,  records: false,  weightClasses: pluWeightClasses},
  {name: "Parasports Drug Tested Junior (20-23)",     code: 'J4',  low: 20,  high: 23,   default: false,  records: false,  weightClasses: pluWeightClasses},
  {name: "Parasports Drug Tested Open",               code: 'O',   low: 24,  high: 39,   default: false,  records: false,  weightClasses: pluWeightClasses},
  {name: "Parasports Drug Tested Sub-Master (35-39)", code: 'SM',  low: 35,  high: 39,   default: false,  records: false,  weightClasses: pluWeightClasses},
  {name: "Parasports Drug Tested Master (40-44)",     code: 'M1',  low: 40,  high: 44,   default: false,  records: false,  weightClasses: pluWeightClasses},
  {name: "Parasports Drug Tested Master (45-49)",     code: 'M2',  low: 45,  high: 49,   default: false,  records: false,  weightClasses: pluWeightClasses},
  {name: "Parasports Drug Tested Master (50-54)",     code: 'M3',  low: 50,  high: 54,   default: false,  records: false,  weightClasses: pluWeightClasses},
  {name: "Parasports Drug Tested Master (55-59)",     code: 'M4',  low: 55,  high: 59,   default: false,  records: false,  weightClasses: pluWeightClasses},
  {name: "Parasports Drug Tested Master (60-64)",     code: 'M5',  low: 60,  high: 64,   default: false,  records: false,  weightClasses: pluWeightClasses},
  {name: "Parasports Drug Tested Master (65-69)",     code: 'M6',  low: 65,  high: 69,   default: false,  records: false,  weightClasses: pluWeightClasses},
  {name: "Parasports Drug Tested Master (70-74)",     code: 'M7',  low: 70,  high: 74,   default: false,  records: false,  weightClasses: pluWeightClasses},
  {name: "Parasports Drug Tested Master (75-79)",     code: 'M8',  low: 75,  high: 79,   default: false,  records: false,  weightClasses: pluWeightClasses},
  {name: "Parasports Drug Tested Master (80+)",       code: 'M9',  low: 80,  high: 999,  default: false,  records: false,  weightClasses: pluWeightClasses},

  {name: "Parasports Untested Junior (10-12)",     code: 'Y1',  low: 10,  high: 12,   default: false,  records: false,  weightClasses: pluWeightClasses},
  {name: "Parasports Untested Junior (13-15)",     code: 'J1',  low: 13,  high: 15,   default: false,  records: false,  weightClasses: pluWeightClasses},
  {name: "Parasports Untested Junior (16-17)",     code: 'J2',  low: 16,  high: 17,   default: false,  records: false,  weightClasses: pluWeightClasses},
  {name: "Parasports Untested Junior (18-19)",     code: 'J3',  low: 18,  high: 19,   default: false,  records: false,  weightClasses: pluWeightClasses},
  {name: "Parasports Untested Junior (20-23)",     code: 'J4',  low: 20,  high: 23,   default: false,  records: false,  weightClasses: pluWeightClasses},
  {name: "Parasports Untested Open",               code: 'O',   low: 24,  high: 39,   default: false,  records: false,  weightClasses: pluWeightClasses},
  {name: "Parasports Untested Sub-Master (35-39)", code: 'SM',  low: 35,  high: 39,   default: false,  records: false,  weightClasses: pluWeightClasses},
  {name: "Parasports Untested Master (40-44)",     code: 'M1',  low: 40,  high: 44,   default: false,  records: false,  weightClasses: pluWeightClasses},
  {name: "Parasports Untested Master (45-49)",     code: 'M2',  low: 45,  high: 49,   default: false,  records: false,  weightClasses: pluWeightClasses},
  {name: "Parasports Untested Master (50-54)",     code: 'M3',  low: 50,  high: 54,   default: false,  records: false,  weightClasses: pluWeightClasses},
  {name: "Parasports Untested Master (55-59)",     code: 'M4',  low: 55,  high: 59,   default: false,  records: false,  weightClasses: pluWeightClasses},
  {name: "Parasports Untested Master (60-64)",     code: 'M5',  low: 60,  high: 64,   default: false,  records: false,  weightClasses: pluWeightClasses},
  {name: "Parasports Untested Master (65-69)",     code: 'M6',  low: 65,  high: 69,   default: false,  records: false,  weightClasses: pluWeightClasses},
  {name: "Parasports Untested Master (70-74)",     code: 'M7',  low: 70,  high: 74,   default: false,  records: false,  weightClasses: pluWeightClasses},
  {name: "Parasports Untested Master (75-79)",     code: 'M8',  low: 75,  high: 79,   default: false,  records: false,  weightClasses: pluWeightClasses},
  {name: "Parasports Untested Master (80+)",       code: 'M9',  low: 80,  high: 999,  default: false,  records: false,  weightClasses: pluWeightClasses},


  {name: "Guest",              code: 'G',                         default: false, records: false,  weightClasses: pluWeightClasses}
];

const getCategory = function (division: Division) {
  let compEvents = "";
  const isSquatting = division.lifts.squat;
  const isBenching = division.lifts.bench;
  const isDeadlifting = division.lifts.dead;
  if (isSquatting && isBenching && isDeadlifting) {
    compEvents = "Full Power";
  } else if (isBenching && isDeadlifting) {
    compEvents = "Push/Pull";
  } else if (isSquatting && isBenching) {
    compEvents = "Squat/Bench";
  } else if (isBenching) {
    compEvents = "Bench Only";
  } else if (isDeadlifting) {
    compEvents = "Deadlift Only";
  } else if (isSquatting) {
    compEvents = "Squat Only";
  }

  return compEvents;
};

const getPluAttemptDisplay = (
  lifter: Lifter,
  liftName: LiftName,
  attemptNumber: AttemptNumber
) => {
  const attempt = getAttemptDisplay(lifter, liftName, attemptNumber);
  const isRecord =
    !!(
      get(lifter, ["lifts", liftName, attemptNumber, "regionalRecord"]) ||
      get(lifter, ["lifts", liftName, attemptNumber, "worldRecord"]) ||
      get(lifter, ["lifts", liftName, attemptNumber, "stateRecord"]) ||
      get(lifter, ["lifts", liftName, attemptNumber, "americanRecord"]) ||
      get(lifter, ["lifts", liftName, attemptNumber, "provincialRecord"]) ||
      get(lifter, ["lifts", liftName, attemptNumber, "canadianRecord"]) ||
      get(lifter, ["lifts", liftName, attemptNumber, "nationalRecord"])
    ) && !attempt.startsWith("-");

  if (isRecord) {
    return `${attempt}*`;
  }

  return attempt;
};

const getEquipment = (eq: EquipmentLevel) => {
  if (eq === "RAW") {
    return "Raw";
  } else if (eq === "RAW_WITH_WRAPS") {
    return "Raw w/ Wraps";
  } else if (eq === "SINGLE_PLY") {
    return "Single Ply";
  } else if (eq === "MULTIPLY") {
    return "Multiply";
  } else if (eq === "UNLIMITED") {
    return "Unlimited";
  }

  return "";
};

export const exportPluResults = function (meet: Meet, dataArray: any) {
  const csvObject: CsvArray = [];
  each(dataArray, (lifter, index) => {
    if (!lifter || lifter.row === "title" || lifter.row === "header") {
      return;
    }

    const division = lifter.division;
    const weightClass = lifter.weightClass;
    const place = lifter.place ? lifter.place : "DQ";
    const genderCodes = {
      MALE: "Male",
      FEMALE: "Female",
      MX: "Neutral",
    };
    const gender = genderCodes[lifter.gender as Gender];
    const baseDivision = pluBaseDivisions.find((bd) => {
      return lifter.division.name.includes(bd.name);
    });
    const ageGroup =
      baseDivision &&
      baseDivision.name.replace("Drug Tested ", "").replace("Untested ", "");
    const rawOrEquipped = getEquipment(division.rawOrEquipped);

    const lifterBirthDate = parseDateString({
      dateString: lifter.birthDate,
      meet,
    });

    const meetDate = parseDateString({ dateString: meet.date, meet });

    const row = {
      place: place,
      name: lifter.name,
      gender: gender,
      birthYear: lifterBirthDate ? format(lifterBirthDate, "yyyy") : "",
      age: getRealAge(lifter, meet),
      country: lifter.country,
      state: lifter.state,
      meetName: meet.name,
      meetLocation: "",
      meetDate: meetDate ? format(meetDate, "yyyy-MM-dd") : "",
      awardsDivision: division.name,
      rawOrEquipped,
      drugTested: lifter.division.name.includes("Drug Tested")
        ? "Tested"
        : "Untested",
      category: getCategory(division),
      ageGroup,
      bodyWeight: lifter.bodyWeight,
      weightClass: weightClass.name,
      squat1: getPluAttemptDisplay(lifter, "squat", "1") || "",
      squat2: getPluAttemptDisplay(lifter, "squat", "2") || "",
      squat3: getPluAttemptDisplay(lifter, "squat", "3") || "",
      bestSquat: getBestAttemptDisplay(lifter, "squat") || "",
      bench1: getPluAttemptDisplay(lifter, "bench", "1") || "",
      bench2: getPluAttemptDisplay(lifter, "bench", "2") || "",
      bench3: getPluAttemptDisplay(lifter, "bench", "3") || "",
      bestBench: getBestAttemptDisplay(lifter, "bench") || "",
      dead1: getPluAttemptDisplay(lifter, "dead", "1") || "",
      dead2: getPluAttemptDisplay(lifter, "dead", "2") || "",
      dead3: getPluAttemptDisplay(lifter, "dead", "3") || "",
      bestDead: getBestAttemptDisplay(lifter, "dead") || "",
      total: getTotal(lifter, division._id, meet) || "",
    };

    csvObject.push(row);
  });

  const meetUnits = capitalize(getMeetUnits(meet));

  const header = {
    place: "Place",
    name: "Name",
    gender: "Sex",
    birthYear: "BirthYear",
    age: "Age",
    country: "Country",
    state: "State",
    meetName: "MeetName",
    meetLocation: "MeetLocation",
    meetDate: "MeetDate",
    awardsDivision: "Division",
    rawOrEquipped: "Equipment",
    drugTested: "DrugTested",
    category: "Category",
    ageGroup: "AgeGroup",
    bodyWeight: `Bodyweight${meetUnits}`,
    weightClass: `WeightClass${meetUnits}`,
    squat1: `Squat1${meetUnits}`,
    squat2: `Squat2${meetUnits}`,
    squat3: `Squat3${meetUnits}`,
    bestSquat: `Best3Squat${meetUnits}`,
    bench1: `Bench1${meetUnits}`,
    bench2: `Bench2${meetUnits}`,
    bench3: `Bench3${meetUnits}`,
    bestBench: `Best3Bench${meetUnits}`,
    dead1: `Deadlift1${meetUnits}`,
    dead2: `Deadlift2${meetUnits}`,
    dead3: `Deadlift3${meetUnits}`,
    bestDead: `Best3Deadlift${meetUnits}`,
    total: `Total${meetUnits}`,
    event: "Event",
  };
  csvObject.unshift(header);

  return csvObject;
};
