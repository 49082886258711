import React from "react";
import get from "lodash/get";
import classNames from "classnames";
import { RecordsForAttempt } from "components/RecordsForAttempt";
import Menu from "./Menu";
import PouchInput from "components/PouchInput";
import ActionButtons from "./ActionButtons";
import { addAttempt } from "util/pouchActions";

import AddIcon from "icons/AddIcon";

import { isCompetingInLift } from "util/lifterHelper";
import { Column } from "components/table/Table";
import { Lifter, Meet } from "types";
import { usePlatform } from "util/usePlatform";

const calculatePlaceholder = (attemptTimerStartTime: number | undefined) => {
  let placeholder = attemptTimerStartTime
    ? 60 - Math.round((Date.now() - attemptTimerStartTime) / 1000)
    : "";
  if (typeof placeholder === "number" && placeholder <= 0) {
    placeholder = "!";
  }
  return placeholder;
};

const AttemptCell = ({
  data,
  column,
  meet,
  style,
}: {
  data: any;
  column: Column;
  meet: Meet;
  style: React.CSSProperties;
}) => {
  const timerRef = React.useRef<NodeJS.Timer>();
  const attemptTimerStartTime =
    column.lift && column.attemptNumber
      ? get(data as Lifter, [
          "lifts",
          column.lift,
          column.attemptNumber,
          "startTime",
        ])
      : undefined;
  const [placeholder, setPlaceholder] = React.useState<
    number | string | undefined
  >(calculatePlaceholder(attemptTimerStartTime));
  React.useEffect(() => {
    const updateTimer = () => {
      setPlaceholder(calculatePlaceholder(attemptTimerStartTime));
      if (
        !attemptTimerStartTime ||
        Date.now() - attemptTimerStartTime > 60000
      ) {
        timerRef.current && clearInterval(timerRef.current);
      }
    };

    const startClock = () => {
      if (attemptTimerStartTime && Date.now() - attemptTimerStartTime < 60000) {
        timerRef.current = setInterval(updateTimer, 1000);
      }
    };

    startClock();

    return () => timerRef.current && clearInterval(timerRef.current);
  }, [attemptTimerStartTime]);

  const addFourthAttempt = () => {
    const lifter = data as Lifter;

    const lifterId = lifter._id;
    const meetId = meet._id;
    const fourthAttempt = {
      liftName: column.lift,
      attemptNumber: "4",
    };

    addAttempt(fourthAttempt, lifterId, meetId);
  };

  const platform = usePlatform();
  const platformId = platform._id;

  const lifter = data;
  if (lifter.row === "header") {
    return (
      <div style={style} className="table-cell-inner">
        {column.label}
      </div>
    );
  }
  if (data.row === "title") {
    return <div style={style} className="table-cell-inner" />;
  }

  const lift = column.lift;
  const attemptNumber = column.attemptNumber;

  if (!lift || !attemptNumber) {
    return null;
  }

  const attempt = (lifter as Lifter).lifts[lift]?.[attemptNumber];

  // Not sure how to handle this. We could be in a situation where we should have a 4th attempt doc but it is missing.
  if ((!attempt || !attempt._id) && column.attemptNumber === "4") {
    return (
      <div style={style} className="table-cell-inner add-fourth-attempt-cell">
        <button onClick={addFourthAttempt}>
          <AddIcon />
        </button>
      </div>
    );
  }

  if (!attempt || !attempt._id) {
    return (
      <div style={style} className="table-cell-inner">
        "ERROR"
      </div>
    );
  }

  if (!isCompetingInLift(lifter, lift, meet)) {
    return null;
  }

  const isCurrentAttempt =
    get(meet, ["platforms", platformId, "currentAttemptId"]) ===
    get(attempt, "_id");

  return (
    <div
      style={style}
      className={classNames(
        "attempt-buttons",
        "table-cell-inner",
        attempt.result || "",
        { "current-attempt": isCurrentAttempt }
      )}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <PouchInput
          style={{ width: "70%" }}
          type="number"
          meetId={meet._id}
          documentId={get(attempt, "_id")}
          name={column.key}
          value={get(attempt, column.key)}
          readOnly={!!attempt.result}
          placeholder={placeholder ? String(placeholder) : undefined}
        />
        {!!attempt.endOfRound && <span>&nbsp;*</span>}
        {!!attempt.juryOverride && <span>&nbsp;J</span>}
        <RecordsForAttempt attempt={attempt} />
        <Menu
          isCurrentAttempt={isCurrentAttempt}
          attempt={attempt}
          meet={meet}
        />
      </div>
      {isCurrentAttempt && <ActionButtons lifter={lifter} column={column} />}
    </div>
  );
};

export default AttemptCell;
