import React, { useEffect, useRef, useState } from "react";
import { Route } from "react-router-dom";

import { login } from "util/pouchAuth";
import { useMeet } from "util/useMeet";
import { useStatus } from "util/useStatus";

const AuthRoute = ({
  openPasswordModal,
  onFinishBulkCreate,
  component: Component,
  ...rest
}: {
  path: string;
  onFinishBulkCreate?: any;
  openPasswordModal: any;
  component: React.ComponentType<any>;
}) => {
  const meet = useMeet();
  const [timeoutPassed, setTimeoutPassed] = useState(false);

  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      setTimeoutPassed(true);
    }, 500);
    return () => timeoutRef.current && clearTimeout(timeoutRef.current);
  }, []);

  const { isLocal, isLoggedIn } = useStatus();
  return (
    <Route
      {...rest}
      render={(matchProps) => {
        if (!isLoggedIn && !isLocal) {
          return (
            <div className="auth-required">
              {timeoutPassed && (
                <div>You must be logged in to access this page</div>
              )}
              {timeoutPassed && (
                <button
                  onClick={() => login(meet._id, null, openPasswordModal)}
                >
                  LOGIN
                </button>
              )}
            </div>
          );
        } else {
          return (
            <Component
              {...matchProps}
              onFinishBulkCreate={onFinishBulkCreate}
              openPasswordModal={openPasswordModal}
            />
          );
        }
      }}
    />
  );
};

export default AuthRoute;
