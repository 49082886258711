import size from "lodash/size";
import React from "react";
import { fetchWrapper } from "util/api";
import { useMeet } from "util/useMeet";

import "./InvoiceStatus.scss";
import { useStatus } from "util/useStatus";
import { endOfTomorrow, isBefore } from "date-fns";
import { parseDateString } from "util/dateHelper";
import { Meet } from "types";

const PaymentPortal = ({ meet }: { meet: Meet }) => {
  return (
    <div style={{ marginTop: 20 }}>
      {meet.contactEmail ? (
        <a
          target="_blank"
          href={`/api/stripe_portal?emailAddress=${encodeURIComponent(
            meet.contactEmail
          )}`}
          rel="noreferrer"
        >
          Click here to enter the LiftingCast Stripe Customer Portal. Where you
          can manage your payment methods and invoices for {meet.contactEmail}.
        </a>
      ) : (
        "Missing Email"
      )}
    </div>
  );
};

const InvoiceStatus = () => {
  const [loading, setLoading] = React.useState(true);
  const [loadingGenerate, setLoadingGenerate] = React.useState(false);
  const [error, setError] = React.useState("");
  const [invoice, setInvoice] = React.useState<null | {
    invoiceStatus: null | string;
    invoicePaid: null | string;
    invoiceUrl: null | string;
    invoiceId: null | string;
  }>(null);
  const meet = useMeet();
  const { isLocal, isOnline } = useStatus();

  const meetDate = parseDateString({ dateString: meet.date, meet });

  const invoiceReady =
    meet._id &&
    isLocal &&
    isOnline &&
    meetDate &&
    isBefore(meetDate, endOfTomorrow());

  React.useEffect(() => {
    const updateInvoice = async () => {
      try {
        setLoading(true);
        const result = await fetchWrapper(
          `/apiv2/meets/${meet._id}/invoice`,
          "GET"
        );
        if (result.ok) {
          setInvoice({
            invoiceId: result.invoiceId ?? null,
            invoiceStatus: result.invoiceStatus ?? null,
            invoicePaid: result.invoicePaid ?? null,
            invoiceUrl: result.invoiceUrl ?? null,
          });
        } else {
          setError("Failed to fetch invoice status.");
        }
        setLoading(false);
      } catch (e: any) {
        console.log(e);
        setError("Failed to fetch invoice status.");
        setLoading(false);
      }
    };
    if (invoiceReady) {
      updateInvoice();
    }
  }, [invoiceReady, isLocal, isOnline, meet._id]);

  const generateInvoice = async () => {
    if (size(meet.lifters) === 0) {
      setError("Your event doesn't have any lifters to bill for.");
      return;
    }
    setLoadingGenerate(true);
    try {
      const result = await fetchWrapper(
        `/apiv2/meets/${meet._id}/invoice`,
        "POST",
        { meetIds: [meet._id] }
      );
      if (result.ok) {
        setInvoice({
          invoiceId: result.invoiceId ?? null,
          invoiceStatus: result.invoiceStatus ?? null,
          invoicePaid: result.invoicePaid ?? null,
          invoiceUrl: result.invoiceUrl ?? null,
        });
      } else {
        setError("Something went wrong generating your invoice.");
      }
      setLoadingGenerate(false);
    } catch (e) {
      console.log(e);
      setError("Something went wrong generating your invoice.");
      setLoadingGenerate(false);
    }
  };

  if (!isLocal || !isOnline) {
    return null;
  }

  if (!invoiceReady) {
    return (
      <div className="invoice-status">
        Your invoice will be available after your meet takes place.
        <PaymentPortal meet={meet} />
      </div>
    );
  }

  if (loading) {
    return <div className="invoice-status"></div>;
  }

  if (loadingGenerate) {
    return (
      <div className="invoice-status">
        <div style={{ fontSize: 24 }}>Generating your invoice...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="invoice-status">
        <div style={{ fontSize: 24, color: "red" }}>{error}</div>
        <PaymentPortal meet={meet} />
      </div>
    );
  }

  if (invoice?.invoiceId && invoice.invoiceUrl) {
    return (
      <div className="invoice-status">
        <div style={{ marginBottom: 20 }}>
          Please pay your invoice after the conclusion of the meet.
        </div>
        <a target="_blank" rel="noreferrer" href={invoice.invoiceUrl}>
          <div>Link to invoice</div>
          <div>Status: {invoice.invoicePaid ? "PAID" : "UNPAID"}</div>
          <div>{invoice.invoiceUrl}</div>
        </a>
        <PaymentPortal meet={meet} />
      </div>
    );
  }

  return (
    <div className="invoice-status">
      <div>Please pay your invoice after the conclusion of the meet.</div>
      <div>
        Invoices usually go out on Monday after your event but if you want to
        pay sooner you can generate the invoice below.
      </div>
      <button onClick={generateInvoice}>
        Click Here To Generate Your Invoice
      </button>
      <PaymentPortal meet={meet} />
    </div>
  );
};

export default InvoiceStatus;
