import React, { useEffect, useRef, useState } from "react";
import Draggable, { DraggableEventHandler } from "react-draggable";
import queryString from "query-string";

// @ts-ignore
import setQuery from "set-query-string";

import ArrowUpIcon from "icons/ArrowUpIcon";
import ArrowDownIcon from "icons/ArrowDownIcon";

const DisplayComponent = ({
  id,
  style,
  canAdjustHeight,
  children,
}: {
  id: string;
  style: React.CSSProperties;
  canAdjustHeight?: boolean;
  children: ({
    sizeMultiplier,
    heightMultiplier,
  }: {
    sizeMultiplier: number;
    heightMultiplier: number;
  }) => React.ReactNode;
}) => {
  const hasInitialized = useRef(false);
  const [state, setState] = useState({
    sizeMultiplier: 1,
    heightMultiplier: 1,
    defaultPosition: { x: 0, y: 0 },
    currentPosition: { x: 0, y: 0 },
  });

  useEffect(() => {
    if (hasInitialized.current) {
      const dataToStore = {
        s: state.sizeMultiplier,
        h: state.heightMultiplier,
        p: state.currentPosition,
      };

      let prevEncodedData: string | (string | null)[] | null = "";
      if (window.location.search) {
        const queryData = queryString.parse(window.location.search);
        prevEncodedData = queryData[id];
      }
      const encodedData = btoa(JSON.stringify(dataToStore));
      if (!prevEncodedData || prevEncodedData !== encodedData) {
        setQuery({ [id]: encodedData });
      }
    }
  }, [id, state]);

  useEffect(() => {
    try {
      if (window.location.search) {
        const queryData = queryString.parse(window.location.search);
        const encodedData = queryData[id];
        if (encodedData && typeof encodedData === "string") {
          const data = JSON.parse(atob(encodedData));
          setState({
            sizeMultiplier: data.s,
            heightMultiplier: data.h,
            defaultPosition: data.p,
            currentPosition: data.p,
          });
        }
      }
    } catch (e) {
      console.log("Failed to parse position data", e);
    }

    hasInitialized.current = true;
  }, [id]);

  const onDragStop: DraggableEventHandler = (event, position) => {
    setState((prev) => ({
      ...prev,
      currentPosition: { x: position.x, y: position.y },
    }));
  };

  const grow = () => {
    setState((prev) => ({
      ...prev,
      sizeMultiplier: prev.sizeMultiplier + 0.1,
    }));
  };

  const shrink = () => {
    setState((prev) => ({
      ...prev,
      sizeMultiplier: prev.sizeMultiplier - 0.1,
    }));
  };

  const increaseHeight = () => {
    setState((prev) => ({
      ...prev,
      heightMultiplier: prev.heightMultiplier + 0.1,
    }));
  };

  const decreaseHeight = () => {
    setState((prev) => ({
      ...prev,
      heightMultiplier: prev.heightMultiplier - 0.1,
    }));
  };

  const { sizeMultiplier, heightMultiplier, defaultPosition } = state;

  return (
    <Draggable
      defaultPosition={defaultPosition}
      position={state.currentPosition}
      onStop={onDragStop}
      cancel=".not-draggable"
    >
      <div
        className="display-component"
        style={{ ...style, position: "absolute" }}
      >
        <div className="resizer">
          <div
            onClick={grow}
            style={{
              display: "flex",
              width: 30,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            +
          </div>
          <div
            onClick={shrink}
            style={{
              display: "flex",
              borderLeft: "1px solid black",
              width: 30,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            -
          </div>
        </div>
        {canAdjustHeight && (
          <div className="height-sizer">
            <div className="resize-button" onClick={increaseHeight}>
              <ArrowUpIcon />
            </div>
            <div className="resize-button" onClick={decreaseHeight}>
              <ArrowDownIcon />
            </div>
          </div>
        )}
        {children({ sizeMultiplier, heightMultiplier })}
      </div>
    </Draggable>
  );
};

export default DisplayComponent;
