import React from "react";
import get from "lodash/get";
import { useParams } from "react-router-dom";
import classNames from "classnames";

import { isCompetingInLift } from "util/lifterHelper";
import { RecordsForAttempt } from "components/RecordsForAttempt";
import { Meet } from "types";

const AttemptCell = ({
  data,
  column,
  meet,
  style,
}: {
  data: any;
  column: any;
  meet: Meet;
  style: React.CSSProperties;
}) => {
  const { platformId } = useParams<{
    platformId: string;
  }>();
  const lifter = data.lifter;
  if (data.row === "header") {
    return (
      <div style={style} className="table-cell-inner">
        {column.label}
      </div>
    );
  } else if (data.row === "title") {
    return <div style={style} className="table-cell-inner" />;
  } else {
    const attempt = get(lifter, ["lifts", column.lift, column.attemptNumber]);
    if (!isCompetingInLift(lifter, get(attempt, "liftName"), meet)) {
      return null;
    }
    const currentAttemptId = get(meet, [
      "platforms",
      platformId,
      "currentAttemptId",
    ]);
    const isCurrentAttempt =
      currentAttemptId === attempt._id && currentAttemptId === data.attemptId;

    return (
      <div
        style={{ ...style, fontSize: (style.fontSize as number) * 1.3 }}
        className={classNames("table-cell-inner", attempt.result || "", {
          "current-attempt": isCurrentAttempt,
        })}
      >
        {get(attempt, "weight")}
        <RecordsForAttempt attempt={attempt} />
        {!!attempt.endOfRound && <span>&nbsp;*</span>}
        {!!attempt.juryOverride && <span>&nbsp;J</span>}
      </div>
    );
  }
};

export default AttemptCell;
