import React from "react";
import { getCompleteLiftingOrder } from "selectors";
import Table from "components/table/Table";
import DefaultTextCell from "components/cells/DefaultTextCell";
import { ReduxState } from "types";
import cloneDeep from "lodash/cloneDeep";
import each from "lodash/each";
import findIndex from "lodash/findIndex";
import get from "lodash/get";
import { useSelector } from "react-redux";
import { useLifter } from "util/useLifter";
import { useMediaSize } from "util/useMediaSize";
import { usePlatform } from "util/usePlatform";
import { useMeet } from "util/useMeet";

const allColumns = [
  { key: "attemptsOut", label: "Out", width: 50, renderer: DefaultTextCell },
  { key: "lifter.name", label: "Name", width: 200, renderer: DefaultTextCell },
  {
    key: "attempt.liftName",
    label: "Lift",
    width: 70,
    renderer: DefaultTextCell,
  },
  {
    key: "attemptNumber",
    label: "Attempt",
    width: 70,
    renderer: DefaultTextCell,
  },
  {
    key: "attempt.weight",
    label: "Weight",
    width: 70,
    renderer: DefaultTextCell,
  },
  { key: "session", label: "Session", width: 70, renderer: DefaultTextCell },
  { key: "flight", label: "Flight", width: 70, renderer: DefaultTextCell },
];

const LifterOrderTable = () => {
  const meet = useMeet();
  const media = useMediaSize();
  const lifter = useLifter();

  const completeLiftingOrder = useSelector((state: ReduxState) =>
    getCompleteLiftingOrder(state, {
      match: {
        params: { meetId: meet._id, platformId: lifter?.platformId ?? "" },
      },
    })
  );

  const currentPlatform = usePlatform(lifter?.platformId ?? "");

  const getCellLines = () => {
    return 1;
  };

  const createDataArray = () => {
    const order: any = cloneDeep(completeLiftingOrder);

    let isPastCurrentAttempt = false;
    let attemptsOut = 0;
    each(order, (attempt) => {
      if (isPastCurrentAttempt) {
        attemptsOut++;
        attempt.attemptsOut = attemptsOut;
      }

      if (attempt.weight && !attempt.attempt.result) {
        isPastCurrentAttempt = true;
      }
    });

    order.unshift({ row: "header" });

    return order;
  };

  const sortedAttempts = createDataArray();
  const currentAttemptIndex = findIndex(
    sortedAttempts,
    (row) => get(row, "attemptId") === get(currentPlatform, "currentAttemptId")
  );

  const scrollToRow = findIndex(
    sortedAttempts,
    (attempt) => get(attempt, ["lifter", "_id"]) === lifter?._id,
    currentAttemptIndex
  );

  return (
    <div className="lifter-order-table">
      <Table
        numberOfFixedLeftColumns={2}
        data={sortedAttempts}
        columns={allColumns}
        getCellLines={getCellLines}
        meet={meet}
        scrollToRow={scrollToRow}
        scrollToAlignment="center"
        hightlightRow={scrollToRow}
        media={media}
      />
    </div>
  );
};

export default LifterOrderTable;
