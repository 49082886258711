import React from "react";

import each from "lodash/each";
import map from "lodash/map";
import find from "lodash/find";
import trim from "lodash/trim";
import startCase from "lodash/startCase";
import toLower from "lodash/toLower";
import get from "lodash/get";
import size from "lodash/size";
import some from "lodash/some";
import Modal from "react-modal";
import { toastr } from "react-redux-toastr";

import RadioGroup from "components/radioGroup/RadioGroup";
import Checkbox from "components/checkbox/Checkbox";

import { addDivision } from "util/pouchActions";
import { getBaseDivisions } from "util/standardDivisions";
import { isLbsMeet } from "util/meetHelper";
import { getRawOrEquippedOptions, getGenderOptions } from "util/options";
import { EquipmentLevel, Gender, Meet } from "types";

type Selections = {
  genders: Partial<Record<Gender, boolean>>;
  equipmentLevel: Partial<Record<EquipmentLevel, boolean>>;
  baseDivisions: Record<string, boolean>;
  lifts: "POWERLIFTING" | "SQUAT" | "BENCH" | "DEAD" | "PUSH_PULL";
};

const getDefaultSelections = (meet: Meet): Selections => {
  const baseDivisionsChecks: Record<string, boolean> = {};
  const baseDivisions = getBaseDivisions(meet);
  each(baseDivisions, (div) => {
    if (div.default) {
      baseDivisionsChecks[div.name] = true;
    }
  });

  const equipmentLevels: Record<string, boolean> = {};
  each(getRawOrEquippedOptions(null, meet), (equipmentLevel) => {
    equipmentLevels[equipmentLevel.value] = true;
  });

  if (equipmentLevels["RAW_WITH_WRAPS"] && meet.federation === "USAPL") {
    equipmentLevels["RAW_WITH_WRAPS"] = false;
  }

  const genders: Record<string, boolean> = {};
  each(getGenderOptions(null, meet), (gender) => {
    genders[gender.value] = gender.value !== "MX";
  });

  return {
    genders,
    equipmentLevel: equipmentLevels,
    baseDivisions: baseDivisionsChecks,
    lifts: "POWERLIFTING" as
      | "POWERLIFTING"
      | "SQUAT"
      | "BENCH"
      | "DEAD"
      | "PUSH_PULL",
  };
};

type PendingDivisions = {
  name: string;
  lifts: {
    squat: boolean;
    bench: boolean;
    dead: boolean;
  };
  gender: Gender;
  rawOrEquipped: EquipmentLevel;
  scoreBy: string;
  usaplDivisionCode: string;
  weightClasses: Record<
    string,
    {
      name: string;
      maxWeight: number;
    }
  >;
}[];

const GenerateModal = ({
  meet,
  isOpen,
  onRequestClose,
  onFinishBulkCreate,
}: {
  meet: Meet;
  isOpen: boolean;
  onRequestClose: () => void;
  onFinishBulkCreate: (count: number) => void;
}) => {
  const [selections, setSelections] = React.useState<Selections>(
    getDefaultSelections(meet)
  );

  const [pendingDivisions, setPendingDivisions] =
    React.useState<PendingDivisions>([]);

  const baseDivisions = getBaseDivisions(meet);

  const onCheck = (
    cat: "genders" | "equipmentLevel" | "baseDivisions",
    id: string,
    value: boolean
  ) => {
    setSelections((prev) => {
      return { ...prev, [cat]: { ...prev[cat], [id]: value } };
    });
  };

  const onRadioSelect = (
    value: "POWERLIFTING" | "SQUAT" | "BENCH" | "DEAD" | "PUSH_PULL"
  ) => {
    if (value !== "POWERLIFTING" && value !== "SQUAT") {
      if (selections.equipmentLevel["RAW_WITH_WRAPS"]) {
        return setSelections((prev) => {
          return {
            ...prev,
            lifts: value,
            equipmentLevel: {
              ...prev["equipmentLevel"],
              RAW_WITH_WRAPS: false,
            },
          };
        });
      }
    }

    if (value !== "BENCH" && selections.baseDivisions["Para Bench"]) {
      return setSelections((prev) => {
        return {
          ...prev,
          lifts: value,
          baseDivisions: {
            ...prev["baseDivisions"],
            "Para Bench": false,
          },
        };
      });
    }

    return setSelections((prev) => {
      return {
        ...prev,
        lifts: value,
      };
    });
  };

  const getDivisionName = (
    meet: Meet,
    gender: Gender,
    equipmentLevel: EquipmentLevel,
    baseDivisionName: string,
    liftNamePostFix: string
  ) => {
    if (meet.federation === "USSF") {
      return trim(
        `${gender === "MALE" ? "Open" : "Women's"} ${
          baseDivisionName === "Open" ? "" : baseDivisionName
        } ${liftNamePostFix}`
      );
    } else {
      const genderPrefixes = {
        MALE: "Men's",
        FEMALE: "Women's",
        MX: meet.federation === "PLU" ? "Gender Neutral" : "Mx",
      };

      if (baseDivisionName === "Para Bench") {
        return trim(`${genderPrefixes[gender]} ${baseDivisionName}`);
      }

      return trim(
        `${genderPrefixes[gender]} ${startCase(
          toLower(equipmentLevel)
        )} ${baseDivisionName} ${liftNamePostFix}`
      );
    }
  };

  const updatePendingDivisions = React.useCallback(
    (selections: Selections) => {
      const newPendingDivisions: PendingDivisions = [];
      let liftNamePostFix = "";
      const lifts = {
        squat: false,
        bench: false,
        dead: false,
      };

      if (selections.lifts === "POWERLIFTING") {
        lifts.squat = true;
        lifts.bench = true;
        lifts.dead = true;
      } else if (selections.lifts === "PUSH_PULL") {
        lifts.bench = true;
        lifts.dead = true;
        liftNamePostFix = "Push/Pull";
      } else if (selections.lifts === "SQUAT") {
        lifts.squat = true;
        liftNamePostFix = "Squat Only";
      } else if (selections.lifts === "BENCH") {
        lifts.bench = true;
        liftNamePostFix = "Bench Only";
      } else if (selections.lifts === "DEAD") {
        lifts.dead = true;
        liftNamePostFix = "Deadlift Only";
      }

      const baseDivisions = getBaseDivisions(meet);
      each(selections.genders, (isGenderOn, gender) => {
        if (isGenderOn) {
          each(
            selections.equipmentLevel,
            (isEquipmentLevelOn, equipmentLevel) => {
              if (isEquipmentLevelOn) {
                each(
                  selections.baseDivisions,
                  (isBaseDivisionOn, baseDivisionName) => {
                    if (
                      baseDivisionName === "Para Bench" &&
                      equipmentLevel !== "RAW"
                    ) {
                      return;
                    }

                    if (isBaseDivisionOn) {
                      const baseDivision = find(baseDivisions, {
                        name: baseDivisionName,
                      });
                      if (!baseDivision) {
                        return;
                      }
                      const weightClasses: Record<
                        string,
                        { name: string; maxWeight: number }
                      > = {};
                      let weightClassData =
                        baseDivision.weightClasses[gender as Gender];

                      each(weightClassData, (weightClass, index) => {
                        let newWeightClass: { name: string; maxWeight: number };
                        if (isLbsMeet(meet)) {
                          newWeightClass = {
                            name: weightClass.lbsName,
                            maxWeight: weightClass.lbsMaxWeight,
                          };
                        } else {
                          newWeightClass = {
                            name: weightClass.name,
                            maxWeight: weightClass.maxWeight,
                          };
                        }
                        weightClasses[`w-${index}`] = newWeightClass;
                      });

                      const newDivision = {
                        name: getDivisionName(
                          meet,
                          gender as Gender,
                          equipmentLevel as EquipmentLevel,
                          baseDivisionName,
                          liftNamePostFix
                        ),
                        lifts,
                        gender: gender as Gender,
                        rawOrEquipped: equipmentLevel as EquipmentLevel,
                        scoreBy: get(baseDivision, "scoreBy", "TOTAL"),
                        usaplDivisionCode: baseDivision.code,
                        weightClasses,
                      };
                      newPendingDivisions.push(newDivision);
                    }
                  }
                );
              }
            }
          );
        }
      });

      setPendingDivisions(newPendingDivisions);
    },
    [meet]
  );

  const divisionCount = pendingDivisions.length;
  const weightClassCount = pendingDivisions.reduce((count, current) => {
    return count + size(current.weightClasses);
  }, 0);
  const duplicates = some(pendingDivisions, (pendingDivision) => {
    return some(meet.divisions, (existingDivision) => {
      return existingDivision.name === pendingDivision.name;
    });
  });

  const generateDivisions = () => {
    pendingDivisions.forEach((newDivision) => {
      addDivision(meet._id, newDivision);
    });
    onRequestClose();

    toastr.success(
      `Created ${divisionCount} new divisions and ${weightClassCount} weight classes`,
      ""
    );
    onFinishBulkCreate(divisionCount);
  };

  React.useEffect(() => {
    updatePendingDivisions(selections);
  }, [selections, updatePendingDivisions]);

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      contentLabel="Generate Divisions"
      onRequestClose={onRequestClose}
      className={{
        base: "generate-divisions-modal",
        afterOpen: "generate-divisions-modal-after-open",
        beforeClose: "generate-divisions-modal-before-close",
      }}
      overlayClassName={{
        base: "generate-divisions-modal-overlay",
        afterOpen: "generate-divisions-modal-overlay-after-open",
        beforeClose: "generate-divisions-modal-overlay-before-close",
      }}
    >
      <div className="division-generate-message">
        <p>
          You can use this division generator to create a division config based
          on your federation's standard divisions.
        </p>
        <p>This process always adds to your existing divisions.</p>
        <p>
          The more divisions you create the slower LiftingCast will load and
          run. Consider unchecking any divisions you will not need.
        </p>
      </div>
      <div className="content">
        <div className="first-columns">
          {meet.federation !== "USSF" && (
            <div className="column">
              <RadioGroup
                onCheck={onRadioSelect}
                value={selections.lifts}
                items={[
                  { label: "Powerlifting", value: "POWERLIFTING" },
                  { label: "Push / Pull", value: "PUSH_PULL" },
                  { label: "Squat Only", value: "SQUAT" },
                  { label: "Bench Only", value: "BENCH" },
                  { label: "Deadlift Only", value: "DEAD" },
                ]}
              />
            </div>
          )}
          <div className="column">
            {map(selections.genders, (value, gender) => {
              return (
                <Checkbox
                  key={gender}
                  label={
                    get(
                      find(getGenderOptions(null, meet), { value: gender }),
                      "label"
                    ) ?? ""
                  }
                  value={!!selections.genders[gender as Gender]}
                  onCheck={(v) => onCheck("genders", gender, v)}
                />
              );
            })}
          </div>
          {meet.federation !== "USSF" && meet.federation !== "100RAW" && (
            <div className="column">
              {map(selections.equipmentLevel, (value, el) => {
                const disabled =
                  el === "RAW_WITH_WRAPS" &&
                  selections.lifts !== "POWERLIFTING" &&
                  selections.lifts !== "SQUAT";

                return (
                  <Checkbox
                    disabled={disabled}
                    key={el}
                    label={startCase(toLower(el))}
                    value={!!selections.equipmentLevel[el as EquipmentLevel]}
                    onCheck={(v) => onCheck("equipmentLevel", el, v)}
                  />
                );
              })}
            </div>
          )}
        </div>
        <div className="column">
          {map(baseDivisions, (division) => {
            const disabled =
              division.code === "PB" && selections.lifts !== "BENCH";

            return (
              <Checkbox
                disabled={disabled}
                key={division.name}
                label={division.name}
                value={selections.baseDivisions[division.name]}
                onCheck={(v) => onCheck("baseDivisions", division.name, v)}
              />
            );
          })}
        </div>
      </div>

      {duplicates ? (
        <div className="division-generate-message error">
          Warning: You are about to create duplicate divisions! Modify your
          selections to continue.
        </div>
      ) : (
        <div className="division-generate-message">
          <p>
            About to generate {divisionCount} divisions and {weightClassCount}{" "}
            weight class categories.
          </p>
          {divisionCount > 200 && (
            <p>
              Generating this many divisions can take a long time. Please be
              patient and wait for loading to complete.
            </p>
          )}
        </div>
      )}
      <div className="button-row">
        <button onClick={onRequestClose} style={{ marginRight: 12 }}>
          Cancel
        </button>
        <button disabled={duplicates} onClick={generateDivisions}>
          Generate
        </button>
      </div>
    </Modal>
  );
};

export default GenerateModal;
