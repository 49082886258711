import find from "lodash/find";
import sortBy from "lodash/sortBy";
import get from "lodash/get";
import uniqWith from "lodash/uniqWith";
import each from "lodash/each";

import { isLbsMeet, getMeetUnits } from "../meetHelper";
import { competitionCode } from "../standardDivisions";
import { getAttemptDisplay } from "../exportData";
import { getRealAge, getYearAge } from "../lifterHelper";
import { FederationDivisionsConfig, Lifter, Meet } from "types";

const male53 = {
  name: "53",
  lbsName: "116.75lbs (53kg)",
  maxWeight: 53,
  lbsMaxWeight: 116.75,
};
const male59 = {
  name: "59",
  lbsName: "130.00lbs (59kg)",
  maxWeight: 59,
  lbsMaxWeight: 130.0,
};
const male66 = {
  name: "66",
  lbsName: "145.50lbs (66kg)",
  maxWeight: 66,
  lbsMaxWeight: 145.5,
};
const male74 = {
  name: "74",
  lbsName: "163.00lbs (74kg)",
  maxWeight: 74,
  lbsMaxWeight: 163.0,
};
const male83 = {
  name: "83",
  lbsName: "182.75lbs (83kg)",
  maxWeight: 83,
  lbsMaxWeight: 182.75,
};
const male93 = {
  name: "93",
  lbsName: "205.00lbs (93kg)",
  maxWeight: 93,
  lbsMaxWeight: 205.0,
};
const male105 = {
  name: "105",
  lbsName: "231.25lbs (105kg)",
  maxWeight: 105,
  lbsMaxWeight: 231.25,
};
const male120 = {
  name: "120",
  lbsName: "264.50lbs (120kg)",
  maxWeight: 120,
  lbsMaxWeight: 264.5,
};
const male120p = {
  name: "120+",
  lbsName: "264.50lbs+ (120kg+)",
  maxWeight: 9999,
  lbsMaxWeight: 9999,
};

const female43 = {
  name: "43",
  lbsName: "94.75lbs (43kg)",
  maxWeight: 43,
  lbsMaxWeight: 94.75,
};
const female47 = {
  name: "47",
  lbsName: "103.50lbs (47kg)",
  maxWeight: 47,
  lbsMaxWeight: 103.5,
};
const female52 = {
  name: "52",
  lbsName: "114.50lbs (52kg)",
  maxWeight: 52,
  lbsMaxWeight: 114.5,
};
const female57 = {
  name: "57",
  lbsName: "125.50lbs (57kg)",
  maxWeight: 57,
  lbsMaxWeight: 125.5,
};
const female63 = {
  name: "63",
  lbsName: "138.75lbs (63kg)",
  maxWeight: 63,
  lbsMaxWeight: 138.75,
};
const female69 = {
  name: "69",
  lbsName: "152.00lbs (69kg)",
  maxWeight: 69,
  lbsMaxWeight: 152.0,
};
const female76 = {
  name: "76",
  lbsName: "167.50lbs (76kg)",
  maxWeight: 76,
  lbsMaxWeight: 167.5,
};
const female84 = {
  name: "84",
  lbsName: "185.00lbs (84kg)",
  maxWeight: 84,
  lbsMaxWeight: 185.0,
};
const female84p = {
  name: "84+",
  lbsName: "185.00lbs+ (84kg+)",
  maxWeight: 9999,
  lbsMaxWeight: 9999,
};

const guest = {
  name: "All Guest",
  lbsName: "All Guest",
  maxWeight: 9999,
  lbsMaxWeight: 9999,
};

const youth = {
  name: "All Youth",
  lbsName: "All Youth",
  maxWeight: 9999,
  lbsMaxWeight: 9999,
};

export const weightClasses = {
  youth: {
    MALE: [youth],
    FEMALE: [youth],
  },
  junior: {
    MALE: [
      male53,
      male59,
      male66,
      male74,
      male83,
      male93,
      male105,
      male120,
      male120p,
    ],
    FEMALE: [
      female43,
      female47,
      female52,
      female57,
      female63,
      female69,
      female76,
      female84,
      female84p,
    ],
  },
  adult: {
    MALE: [male59, male66, male74, male83, male93, male105, male120, male120p],
    FEMALE: [
      female47,
      female52,
      female57,
      female63,
      female69,
      female76,
      female84,
      female84p,
    ],
  },
  guest: {
    MALE: [guest],
    FEMALE: [guest],
  },
};

// prettier-ignore
export const powerliftingAmericaBaseDivisions: FederationDivisionsConfig = [
  {name: "Youth",          code: 'Y',   low: 8,   high: 11,   default: true,  records: false, weightClasses: weightClasses.youth, scoreBy: "IPF_POINTS"},
  {name: "Pre-Sub-Junior", code: 'PS',  low: 12,  high: 13,   default: true,  records: true,  weightClasses: weightClasses.junior},
  {name: "Sub-Junior",     code: 'SJ',  low: 14,  high: 18,   default: true,  records: true,  weightClasses: weightClasses.junior},
  {name: "Junior",         code: 'J',   low: 18,  high: 23,   default: true,  records: true,  weightClasses: weightClasses.junior},
  {name: "Open",           code: 'O',   low: 24,  high: 39,   default: true,  records: true,  weightClasses: weightClasses.adult},
  {name: "Master I",       code: 'M1',  low: 40,  high: 49,   default: true,  records: true,  weightClasses: weightClasses.adult},
  {name: "Master II",      code: 'M2',  low: 50,  high: 59,   default: true,  records: true,  weightClasses: weightClasses.adult},
  {name: "Master III",     code: 'M3',  low: 60,  high: 69,   default: true,  records: true,  weightClasses: weightClasses.adult},
  {name: "Master IV",      code: 'M4',  low: 70,  high: 999,  default: true,  records: true,  weightClasses: weightClasses.adult},
  {name: "Guest",          code: 'G',   low: 999, high: 9999, default: false, records: false, weightClasses: weightClasses.guest}
];

export const getPowerliftingAmericaAge = function (lifter: Lifter, meet: Meet) {
  const realAge = getRealAge(lifter, meet);
  const yearAge = getYearAge(lifter, meet);
  if (!yearAge || !realAge) {
    return null;
  }

  if (realAge <= 14) {
    return realAge;
  }

  return yearAge;
};

const getLifterPowerliftingAmericaDivision = function (
  lifter: Lifter,
  meet: Meet
) {
  const divisionAge = getPowerliftingAmericaAge(lifter, meet);
  if (divisionAge && divisionAge >= 8) {
    return powerliftingAmericaBaseDivisions.find((division) => {
      return (
        division.default &&
        division.low &&
        division.high &&
        divisionAge >= division.low &&
        divisionAge <= division.high
      );
    });
  }

  return null;
};

export const getLifterPowerliftingAmericaWeightClass = function (
  lifter: Lifter,
  code: string,
  meet: Meet
) {
  const division = find(powerliftingAmericaBaseDivisions, {
    code,
  });

  if (lifter.gender && division && division.name) {
    if (lifter.gender === "MX") {
      return;
    }

    const weightClasses = division.weightClasses[lifter.gender];
    const bodyWeight = lifter.bodyWeight;
    if (!bodyWeight) {
      return;
    }

    return find(weightClasses, (wc) => {
      let maxWeight = wc.maxWeight;
      if (isLbsMeet(meet)) {
        maxWeight = wc.lbsMaxWeight;
      }
      return bodyWeight <= maxWeight;
    });
  }
};

type PowerliftingAmericaResult = Partial<
  Record<
    | "name"
    | "team"
    | "division"
    | "bodyWeight"
    | "weightClass"
    | "birthDate"
    | "lot"
    | "squat1"
    | "squat2"
    | "squat3"
    | "bench1"
    | "bench2"
    | "bench3"
    | "dead1"
    | "dead2"
    | "dead3"
    | "compEvents"
    | "state"
    | "memberNumber"
    | "place",
    string | number | null | undefined
  >
>;

export const exportPowerliftingAmericaResults = function (
  meet: Meet,
  dataArray: any
) {
  let csvObject: PowerliftingAmericaResult[] = [];

  each(dataArray, (lifter, index) => {
    if (!lifter || lifter.row === "title" || lifter.row === "header") {
      return;
    }

    const division = lifter.division;
    const gender = lifter.gender === "MALE" ? "M" : "F";
    const rawOrEquipped = find(
      lifter.divisions,
      (ld) => ld.rawOrEquipped === "EQUIPPED"
    )
      ? ""
      : "R";
    let usaplDivisionCode = division.usaplDivisionCode;
    if (!usaplDivisionCode || usaplDivisionCode === "AUTO") {
      const standardDivision = getLifterPowerliftingAmericaDivision(
        lifter,
        meet
      );
      if (standardDivision) {
        usaplDivisionCode = standardDivision.code;
      }
    } else if (usaplDivisionCode === "HIDE") {
      return;
    }
    const weightClass = getLifterPowerliftingAmericaWeightClass(
      lifter,
      usaplDivisionCode,
      meet
    );
    const combinedDivisionCode = `${gender}${rawOrEquipped}-${
      usaplDivisionCode || ""
    }`;

    const compEvents = competitionCode(division);

    // add division info to determine if cell should be displayed
    lifter = {
      ...lifter,
      division: {
        lifts: division.lifts,
      },
    };

    const row: PowerliftingAmericaResult = {
      name: lifter.name,
      team: lifter.team,
      division: combinedDivisionCode,
      bodyWeight: lifter.bodyWeight,
      weightClass: get(weightClass, "name", ""),
      birthDate: lifter.birthDate,
      lot: lifter.lot,
      squat1: getAttemptDisplay(lifter, "squat", "1"),
      squat2: getAttemptDisplay(lifter, "squat", "2"),
      squat3: getAttemptDisplay(lifter, "squat", "3"),
      bench1: getAttemptDisplay(lifter, "bench", "1"),
      bench2: getAttemptDisplay(lifter, "bench", "2"),
      bench3: getAttemptDisplay(lifter, "bench", "3"),
      dead1: getAttemptDisplay(lifter, "dead", "1"),
      dead2: getAttemptDisplay(lifter, "dead", "2"),
      dead3: getAttemptDisplay(lifter, "dead", "3"),
      compEvents: compEvents,
      state: lifter.state,
      memberNumber: get(lifter, "restricted.memberNumber"),
      place: lifter.place,
    };

    csvObject.push(row);
  });

  csvObject = sortBy(csvObject, [
    "compEvents",
    "division",
    (row) =>
      row.weightClass && typeof row.weightClass === "string"
        ? parseInt(row.weightClass, 10)
        : 0,
  ]);
  csvObject = uniqWith(csvObject, (a, b) => {
    return (
      a.name === b.name &&
      a.division === b.division &&
      a.compEvents === b.compEvents &&
      a.bodyWeight === b.bodyWeight &&
      a.birthDate === b.birthDate &&
      a.memberNumber === b.memberNumber
    );
  });

  // ref data
  csvObject.push({});
  csvObject.push({
    name: "Ref Name",
    team: "",
    division: "",
    bodyWeight: "",
    weightClass: "",
    birthDate: "Ref DOB",
    lot: "",
    squat1: "CHIEF",
    squat2: "SIDE",
    squat3: "WEIGH-IN",
    bench1: "ANNOUNCER",
    bench2: "TC",
    bench3: "JURY",
    dead1: "SCORING",
    dead2: "EQUIPMENT CHECK",
    dead3: "",
    compEvents: "Event (RF for all refs)",
    state: "Ref State",
    memberNumber: "Ref MemberID",
    place: "",
  });

  const header: PowerliftingAmericaResult = {
    name: "Name",
    team: "Team",
    division: "Div",
    bodyWeight: `Bwt - ${getMeetUnits(meet)}`,
    weightClass: "Wt Cls",
    birthDate: "DOB",
    lot: "Lot #",
    squat1: "Squat 1",
    squat2: "Squat 2",
    squat3: "Squat 3",
    bench1: "Bench 1",
    bench2: "Bench 2",
    bench3: "Bench 3",
    dead1: "Deadlift 1",
    dead2: "Deadlift 2",
    dead3: "Deadlift 3",
    compEvents: "Event",
    state: "State",
    memberNumber: "MemberID",
    place: "Placing",
  };

  csvObject.unshift(header);

  return csvObject;
};
