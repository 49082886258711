import { Column } from "components/table/Table";
import React from "react";

const PlaceCell = ({
  data,
  column,
  style,
}: {
  data: any;
  column: Column;
  style: React.CSSProperties & { fontSize: number };
}) => {
  const lifter = data;
  if (lifter.row === "header") {
    return (
      <div style={style} className="table-cell-inner">
        {column.label}
      </div>
    );
  } else if (lifter.row === "title") {
    return <div style={style} className="table-cell-inner" />;
  } else {
    return (
      <div style={style} className="table-cell-inner">
        {lifter.place}
      </div>
    );
  }
};

export default PlaceCell;
