import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { getLifter } from "selectors";
import { Lifter, ReduxState } from "types";

export const useLifter = (): Lifter | undefined | null => {
  const { meetId, lifterId } = useParams<{
    meetId: string;
    lifterId: string;
  }>();

  const props = React.useMemo(() => {
    return { match: { params: { meetId, lifterId } } };
  }, [meetId, lifterId]);

  const lifter = useSelector((state: ReduxState) => getLifter(state, props));

  return lifter;
};
