import React from "react";
import { Link } from "react-router-dom";
import kebabCase from "lodash/kebabCase";
import "./About.scss";
import ConnectedIcon from "icons/ConnectedIcon";
import ThumbsUpIcon from "icons/ThumbsUpIcon";
import ThumbsDownIcon from "icons/ThumbsDownIcon";
import ClockIcon from "icons/ClockIcon";
import ResetIcon from "icons/ResetIcon";
import AddIcon from "icons/AddIcon";

const Question = ({
  slug,
  title,
  children,
}: {
  slug?: string;
  title: string | React.ReactNode;
  children: React.ReactNode;
}) => {
  const anchor = slug ?? (typeof title === "string" ? kebabCase(title) : "");
  return (
    <div className="question">
      <a href={`#${anchor}`} id={anchor} className="question-title">
        {title}
      </a>
      <div className="question-answer">{children}</div>
    </div>
  );
};

const About = () => {
  React.useEffect(() => {
    setTimeout(() => {
      const { hash } = window.location;
      const id = hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.focus();
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 250);
  }, []);

  return (
    <div className="about">
      <div>
        <Link to="/">Go Home</Link>
      </div>
      <div style={{ marginTop: 20 }}>
        <a href="/changes.html">Check the changelog to recent updates</a>
      </div>
      <h2>FAQ</h2>

      <Question title="Tips for success">
        <ul>
          <li>
            Start setting up your meet at least a week in advance. Never wait
            until the day of your meet to import lifter data.
          </li>
          <li>
            After uploading make sure you've kept track of the password you have
            chosen. Make sure you can log into other computers with the
            password.
          </li>
          <li>
            Hook up your platform display directly to one of the computers at
            the scoring table. If you have internet connection issues you can
            run the meet offline with that one computer.
          </li>
          <li>
            If you are using LiftingCast in online mode have a backup internet
            source. A cellular hotspot with a decent connection can easily run
            LiftingCast on several computers.
          </li>
          <li>
            If you are using LiftingCast in offline only mode download the meet
            data file from the setup page regularly to backup your meet. Ideally
            you should backup at least after every flight.
          </li>
          <li>
            Be sure you understand how to calculate all awards you are giving
            out including best lifter awards.
          </li>
          <li>
            As with all technology always have a backup plan if there are
            unexpected issues.
          </li>
          <li>
            These instructions are always being added to. It is a good idea to
            reread them before each meet as a refresher.
          </li>
          <li>
            Regardless of if you are using offline or online mode you should
            download your meet data file from the setup page after any major
            data changes or after online registration closes.
          </li>
        </ul>
      </Question>

      <Question title="What is the meet data file?">
        <p>
          The meet data file can be downloaded from the setup page. This file
          contains all LiftingCast data for your meet. You can use it to create
          a new event by uploading it from the homepage. There is a button
          labeled "Upload Meet From Data File" towards the button of the
          homepage.
        </p>
        <p>
          It is recommended to download the meet data file before and after any
          major data changes. This will let you recover any data you might have
          accidentally lost.
        </p>
        <p>
          If you are using offline mode only you should download the meet data
          file often. It is the only way to have a backup of your data. When
          using offline only mode you data is only stored in the browser. This
          browser storage is not reliable so you need to meet data file as a
          backup.
        </p>
      </Question>

      <Question title="Backup Server">
        <p>
          There is a backup server located at{" "}
          <a href="https://backup.liftingcast.com">
            https://backup.liftingcast.com
          </a>
          . This server replicates all data uploaded to the main LiftingCast
          site (<a href="https://liftingcast.com">https://liftingcast.com</a>).
          Either site can be used to run your meet. liftingcast.com is run on a
          server located in New York, USA. backup.liftingcast.com runs on a
          server located in California, USA. You may get better sync performance
          from the server geographically closer to you.
        </p>
      </Question>

      <Question title="What are the supported browsers?">
        <p>
          LiftingCast only officially supports the{" "}
          <a href="https://www.google.com/chrome/">Chrome browser</a>. You can
          use Chrome on Windows, macOS, Linux, and Chrome OS. Chromebooks are
          good cheap laptops for running LiftingCast. Other browsers should work
          but we recommend using Chrome for the best experience.
        </p>
      </Question>

      <Question title="How do I get an account?">
        <p>
          You don't need an account to create a meet in the system. Anyone can
          create new offline meet. You can then upload the meet online and set a
          password. You set a password for each event. Each event's password
          should be unique. You don't have single account with LiftingCast. Each
          meet is it's own thing. This ensures that when you give someone access
          to a specific meet they only have access to that meet.
        </p>
      </Question>

      <Question title="How much does it cost?">
        <p>
          LiftingCast is free to use in offline mode. This means running the
          meet from a single computer with no data uploaded to the cloud
          database. If you would like to sync meet data with multiple computers
          and upload data for online viewing you must upload the meet. Uploading
          the meet cost $1.25 per lifter to be paid at the end of the event.
        </p>
      </Question>

      <Question title="How to run a meet?">
        <p>
          <Link to="/instructions">
            More detailed documentation can be found here.
          </Link>
        </p>
        <p>
          Create a new meet by clicking the "Create New Meet" button at the
          bottom of the home page.
        </p>
        <p>Click on the meet name and go to the setup page.</p>
        <p>
          Here you can optionally upload data online and set a password. Each
          meet has its own password. If you are using the system in offline only
          mode you don't need a password.
        </p>
        <p>
          It is recommended to upload data online as a way to backup data. The
          local data is stored in your browser. Browser data storage is meant
          for short term storage and is not reliable long term.
        </p>
        <p>
          Import, generate, or enter divisions and weight classes. For single or
          two lift divisions be sure the division name and the selected lifts
          match. For example if you have a bench only division be sure that only
          'Bench' is checked under lifts.
        </p>
        <p>
          Import or enter lifters. If you used the online registration, your
          lifters will already be entered.
        </p>
        <p>DO NOT WAIT UNTIL THE DAY OF THE MEET TO IMPORT LIFTERS!</p>
        <p>
          Make sure you have platform, session, and flight entered for every
          lifter. For single session meets you still need to enter session 1 for
          every lifter.
        </p>
        <p>
          Only enter each lifter once. Additional divisions can be added to the
          lifter.
        </p>
        <p>Print cards just before meet day.</p>
        <p>
          On meet day right after weigh ins enter body weights, rack heights and
          opening attempts on weight in or lifters page.
        </p>
        <p>Select first lifter on run page for each platform.</p>
        <p>
          Start the clock on bar is loaded by clicking the <ClockIcon /> or
          pressing the "S" key.
        </p>
        <p>
          If needed you can reset the clock to 1:00 by clicking the{" "}
          <ResetIcon /> icon or the "R" key.
        </p>
        <p>
          Mark the result of the lift by clicking the <ThumbsUpIcon /> or{" "}
          <ThumbsDownIcon />. You can also press the "G" or "B" keys for good
          and bad lifts. After marking the lift result the clock will
          automatically reset.
        </p>
        <p>
          Make sure you are not focused in an input field when using the
          shortcut keys. The program will think you are trying to type in those
          fields.
        </p>
        <p>
          A 60 second countdown timer will start in the lifter's next attempt
          field. This represents the time remaining for them to submit their
          next attempt. This timer is also shown on the top of the Board page.
        </p>
        <p>
          Enter lifter's next attempt weight. The attempt weight is
          automatically saved when press "return", "tab", or you unfocus from
          the field.
        </p>
        <p>Continue until meet is over.</p>
        <p>
          If you need to adjust the order you can manually set the current
          attempt by clicking the "..." menu icon next to the attempt and
          selecting "Set as current attempt".
        </p>
        <p>
          If you make a mistake in marking an attempt you can you can modify it
          by clicking the "..." menu icon next to the attempt and selecting
          "Mark as good attempt" or "Mark as bad attempt".
        </p>
        <p>
          If a lifter needs to re-lift at the end of the round. Click the "..."
          icon on the attempt. Select "Move to end of round". This will place
          the lifter at the end of the round. Use the "Clear result" option if
          needed.
        </p>
        <p>
          After entering an attempt weight you can mark that an attempt is a
          record by clicking the "..." menu icon. This will cause "RECORD_TYPE
          Record Attempt" to show up on the main display.
        </p>
        <p>View results at any time during meet.</p>
      </Question>

      <Question title="How do I import lifters and divisions?">
        <p>
          Lifters and divisions can be imported and exported from the system.
          The export and import file format is the same. So you can export a
          division list from one meet directly into another.
        </p>
        <p>DO NOT WAIT UNTIL THE DAY OF THE MEET TO IMPORT LIFTERS!</p>
        <p>
          Go to the Import page. Import Divisions tab is selected by default.
        </p>
        <p>
          You can download an example export/import file using the "Download
          Template" button on the top right.
        </p>
        <p>Do not change the columns at the top of the file.</p>
        <p>
          These divisions should match exactly what you will be giving awards
          for. They do not have to match your federations standard list of
          divisions. You have a lot of flexibility in creating any awards
          division setup you need.
        </p>
        <p>
          For divisions you should fill out all fields.
          <a href="/example_meet_divisions_import_format.csv">
            See example division import file here.
            example_meet_divisions_import_format.csv
          </a>
        </p>
        <p>
          Make sure you retain the "csv" file format. You cannot import
          Microsoft Excel or Apple Numbers formats.
        </p>
        <p>Click "Select Divisions file" button.</p>
        <p>Choose your file.</p>
        <p>You should see a preview of the data you will be importing.</p>
        <p>
          Verify that the data is correct. If anything looks wrong or their are
          missing fields do no proceed with the import. Go back to your csv file
          and fix the issue. Usually this is caused because you did not match
          the required text exactly.
        </p>
        <p>
          Click "Confirm Import of Divisions" to finish import. A loading screen
          will appear. Wait until the loading screen stops before continuing.
        </p>
        <p>
          The import divisions page will now be empty. Your divisions can now be
          found on the "Awards Divisions" page.
        </p>
        <p>
          To import lifters click the "Import Lifters" link on the top left.
          Lifters should be imported after divisions are created.
        </p>
        <p>
          Download the lifters template file.{" "}
          <a href="/example_meet_lifters_import_format.csv">
            See example lifters import file here.
            example_meet_lifters_import_format.csv
          </a>
        </p>
        <p>
          Fill out the file as completely as you can. You don't have to fill out
          all fields but at minimum you should fill out lifters name.
        </p>
        <p>
          Platform names, Divisions Names, and Weight class names must match
          exactly.
        </p>
        <p>
          You can add additional divisions after by adding extra rows with just
          division info after a lifter. See example file above. Do not repeat
          the lifter's name or other info for the additional division row. Only
          "rawOrEquipped", "division", and "declaredAwardsWeightClass" should be
          filled out in the additional division row.
        </p>
        <p>Click "Select Lifters file" button.</p>
        <p>
          Confirm the data in the preview table is correct. If you don't see
          platforms, divisions, or weight classes that means the data did not
          match. Go back to the import file, correct the issues and start the
          import process over.
        </p>
        <p>
          Click "Confirm Import of Lifters" to finish import. A loading screen
          will appear. Wait until the loading screen stops before continuing.
        </p>
        <p>
          The import lifters page will now be empty. Your lifters can now be
          found on the "Lifters" page.
        </p>
      </Question>

      <Question title="How to handle lifters that are entered in multiple divisions?">
        <p>
          Only enter each lifter once. You can add additional divisions to a
          lifter from the "Lifters" page. Click the <AddIcon /> icon next to the
          lifter's division then enter "R/E", "Awards Divisions", and "Declared
          Awards Weight Class" for each division.
        </p>
      </Question>

      <Question title="How are age coefficients calculated?">
        <p>
          Age coefficients are calculated based on lifters age on the day of the
          meet.
        </p>
        <p>
          Foster and McCulloch formulas are used for all federations other than
          USAPL.
        </p>
        <a href="http://www.usaplmn.com/wp-content/uploads/2014/04/USAPL-Age-Coefficients.pdf">
          More info here about Foster and McCulloch.
        </a>
        <br />
        <br />
        USAPL uses their own age coefficients that can be found here
        <a href="https://www.usapowerlifting.com/lifters-corner/">
          https://www.usapowerlifting.com/lifters-corner/
        </a>
      </Question>

      <Question title="How are ties handled?">
        <p>
          When scoring by total. If two lifters score the same total, the
          lighter lifter wins. If two lifters score the same total and weight
          the same, the lifter who achieved th total first wins.
        </p>
        <p>
          When scoring by formula. The lifter who achieves the formula value
          first wins.
        </p>
      </Question>

      <Question title="What should I do if a lifter passes on an attempt?">
        <p>
          If a lifter wants to pass on an attempt and they have already
          submitted an attempt value. You can mark the attempt unsuccessful with
          the "..." menu next to the attempt number. If the lifter wants to pass
          on an attempt and they have not submitted an attempt value. You can
          put 0 in the attempt field and the attempt will be skipped. It is best
          to also mark the attempt unsuccessful to make it more clear in the
          results.
        </p>
      </Question>

      <Question
        slug="what-do-the-icons"
        title={
          <span>
            What do the <ConnectedIcon isSyncing={true} />,{" "}
            <ConnectedIcon isSyncing={true} className="sync-active" /> and{" "}
            <ConnectedIcon className="sync-disabled" /> icons mean in the top
            right of the screen?
          </span>
        }
      >
        <p>
          This icon represents the status of syncing data when you have uploaded
          the meet online.
        </p>
        <p>
          When you are working on a meet that you have logged into in the past a
          local copy of the data is stored in your browser. This local copy of
          the data is kept in sync with the online data.
        </p>
        <p>
          If you see the sync active icon (<ConnectedIcon isSyncing={true} />)
          that means you are currently connected and syncing. If the icon turns
          green that indicates that meet data is actively transferring. Either
          from or to your computer.
        </p>
        <p>
          The sync inactive icon (<ConnectedIcon className="sync-disabled" />)
          indicates that your are not currently connected and syncing. This is
          either because you are not logged in or not connected to the internet.
          While in this state you can continue to work offline and your changes
          will be synced when you next come online. It is recommended to not
          edit the meet, or each platform, in offline mode with more than one
          computer at a time to avoid conflicting data.
        </p>
        <p>
          If you need to work offline for extended periods of time you can
          backup your data by downloading the meet data file on the setup page.
        </p>
      </Question>

      <Question title="How to determine best lifters?">
        <p>You have two options for determining best lifters.</p>
        <p>
          You can setup best lifter divisions for each best lifter award you are
          giving out. Assign lifters to these division as an additional
          division.
        </p>
        <p>
          You can export the results by clicking the "Export Awards Results"
          button. This file will contain all the info you need to determine best
          lifters. Open the .csv file with your favorite spreadsheet programe
          (Excel, Google Docs) and sort the columns as needed.
        </p>
      </Question>

      <Question title="How to hook up platform TV?">
        <p>
          If possibly platform TV should be plugged directly into the computer
          used to start the timer / mark attempt results.
        </p>
        <p>
          Use the TV as a second monitor. The TV should have the display page on
          it. You can drag display components that you don't need off the bottom
          of the screen. Other components can be moved and resized to customize
          the display. Hover over display elements to move or resize. The +/-
          buttons on the top left can be used to resize. On the order table only
          the up/down arrows on the top right can be used to change the height.
        </p>
        <p>
          This configuration will cause the TV to update the fastest and provide
          the most resiliency against network issues.
        </p>
      </Question>

      <Question title="How to handle guest lifters?">
        <p>
          Create a guest lifter division for each equipped / raw / gender /
          lifts category. Use this as a bucket for guest only lifters. The
          "Generate Divisions" button on the "Awards Divisions" page will have
          an option to generate guest lifter divisions.
        </p>
      </Question>

      <Question title="How to create a division with no weight classes?">
        <p>
          All divisions must have at least one weight class. Create a weight
          class called "All" and give it a high max weight like "999" so all
          lifters will be assigned to that weight class. When adding a single
          blank division it will be created with an "All" weight class. This
          setup is commonly used when scoring a division by formula regardless
          of weight class.
        </p>
      </Question>

      <Question title="I can't enter opening attempts or rack heights?">
        <p>
          LiftingCast relies on the selected divisions to know which lifts a
          lifter is participating in. Make sure every lifter has at least one
          division set. If you still don't see the opening attempt input fields
          go to the Awards Divisions page and make sure all your divisions have
          the correct lifts checked. There is a "Problem Checker" tool linked
          from the setup page. This tool can help diagnose issues like this.
        </p>
      </Question>

      <Question title="How to setup online registration?">
        <p>
          You'll need a Stripe account{" "}
          <a href="https://stripe.com">https://stripe.com</a> to use the online
          registration. After creating a Stripe account you will need to install
          the LiftingCast Payment Link app into your Stripe Account.{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://marketplace.stripe.com/apps/liftingcast-payment-link"
          >
            https://marketplace.stripe.com/apps/liftingcast-payment-link
          </a>
        </p>
        <p>On setup page.</p>
        <p>Don't check "Registration is open" yet.</p>
        <p>
          Enter the maximum number of entries allowed. The system will not allow
          any more than this number to sign up.
        </p>
        <p>
          Select a currency. Enter the cost for the a full powerlifting entry.
          You must enter at least $1 or equivalent base unit for your currency.
        </p>
        <p>
          If you are offering single or two lift divisions enter the cost for
          those entries.
        </p>
        <p>
          If you want to allow more than one division, enter the cost for
          additional divisions. If you leave this blank lifters will only be
          able to sign up for one division.
        </p>
        <p>
          Enter a description of the meet. Things like location, date, time, and
          sanction number.
        </p>
        <p>
          If you want to require lifters to agree to a disclaimer, enter text in
          the disclaimer field. Lifters will be required to type "I AGREE"
          before submitting registration.
        </p>
        <p>If you have a website, enter the full url. (https:://example.com)</p>
        <p>
          Enter your TEST stripe key from your LiftingCast Payment Link app.
        </p>
        <p>
          If you would like to ask custom questions, click "Add Custom
          Question". Check out the registration link so see what the default
          questions are. Currently only plain text responses are supported. You
          can check "required" and then the lifter must answer the question to
          submit registration.
        </p>
        <p>
          If you would like to sell items such as t-shirts click "Add Item for
          Sale". Enter item description and price. For selling t-shirts you'll
          want to create a custom item for each t-shirt size. You are
          responsibly for including any required Sales Tax or VAT in the price
          of the item.
        </p>
        <p>
          Make sure you have all your awards divisions setup. The divisions the
          lifter is able to register for are based on your division
          configuration on the Awards Divisions page.
        </p>
        <p>
          Click on the registration link and fill out a test registration. For
          the credit card number use 4242 4242 4242 4242, exp 03/33, CCV 333
        </p>
        <p>On the Lifters page verify your registration, then delete it.</p>
        <p>Back on the setup page.</p>
        <p>
          Enter your LIVE stripe keys from the LiftingCast Payment Link app. You
          can only view this key once, so make sure you save it in a secure
          location such as a password manager.
        </p>
        <p>When ready to open registration, check "Registration is open"</p>
      </Question>

      <Question title="Can I use pounds instead of kilograms?">
        <p>
          Yes, select LBS for units when creating a meet. Once you create a meet
          you can't change the units. All units for meet have to be the same,
          you can't mix lbs for the platform and kg for weigh-ins.
        </p>
      </Question>

      <Question title="Which Federations are supported?">
        <p>
          You can select from some pre-setup federations. You can also use the
          "Other" federation. There is no requirement that you are officially
          associated with the federation you are selecting. Just select the
          federation config that best matches your meet.
        </p>
        <p>
          Different federations will auto generate different divisions / weight
          classes. Regardless of what federation you choose you can customize
          your divisions and weight classes as you need. If you do need to
          create a custom division and weight class setup you can export that
          config and reuse it for future meets.
        </p>
        <p>
          Some federations have custom result export formats and membership
          checkers.
        </p>
        <p>
          We will add support for more federations if there is demand. If your
          federation is running more than 5 meets a month and would like to be
          added to the federation selection list please contact us. Since the
          divisions and weight classes are highly customizable you can always
          select a different federation or the "Other" federation and still make
          it work.
        </p>
      </Question>

      <Question title="How do I handle 4th attempts?">
        <p>
          If you select a federation that allows 4th attempts you should see a
          4th attempt column for each lift. On the run page click the + icon in
          the 4th attempt cell to enable the 4th attempt for that lifter. The
          attempt will be selected in the normal flow of the meet but won't be
          counted in the lifter's total.
        </p>
      </Question>

      <Question title="What if my meet will span multiply days?">
        <p>There are two ways to setup your meet for multiply days.</p>
        <p>
          You can create a LiftingCast meet for each day. Add "Day 1, Day2,
          etc." to each meet's name.
        </p>
        <p>
          You can change the meet date from the setup page after every day of
          lifting is complete. The downside of this approach is that any age
          based formula may be thrown off by a day.
        </p>
        <p>
          If you have a large meet (more than 300 lifters) it is recommended
          that you create a new meet for each day. This will give you improved
          data syncing performance.
        </p>
      </Question>

      <Question title="The attempt box on the Run page says ERROR?">
        <p>
          Possible sync issue from import. Make sure the computer you imported
          on is fully synced before closing browser. Green sync icon (
          <ConnectedIcon isSyncing={true} className="sync-active" />) turns
          white (<ConnectedIcon isSyncing={true} />
          ). If you still see this error you will have to delete and manually
          recreate this lifter.
        </p>
      </Question>

      <Question title="How to setup a custom background image on the display page?">
        <p>
          On the setup page there is a display config section. Upload your image
          to a host that supports hosting external images over SSL. Your image
          address must start with "https://". After setting background image
          refresh the display page. If The background image is stuck as white
          your image can't be fetch from the URL. Uploading a background image
          will override any custom background color you have set.
        </p>
      </Question>

      <Question title="I checked 'Show on Homepage' but my meet isn't showing on the homepage?">
        <p>
          Make sure you have uploaded the event to the cloud database and that
          you are logged in and syncing. Updates to the homepage can take up to
          10 minutes.
        </p>
      </Question>

      <Question title="My meet isn't showing up anymore?">
        <p>
          LiftingCast is not meant to be long term storage of your meet results.
          Meets are auto removed from the system 1 month after your event. After
          running an event please download the meet data file from the setup
          page. This will allow you to recreate the meet if needed.
        </p>
        <p>
          You should also download your result file(s), lifters export, and
          division export.
        </p>
      </Question>

      <Question title="When do I pay for the online version?">
        <p>LiftingCast is free when using it in offline mode.</p>
        <p>
          If you uploaded your meet to the cloud database. You pay for your
          event after the event has finished. You can generate an invoice from
          the setup page. Otherwise invoices are sent to your contact email
          address the following week.
        </p>
      </Question>

      <Question title="I have more questions.">
        <strong>
          If you are a lifter or coach you should contact the meet director with
          any questions.
        </strong>
        <br />
        <br />
        <div>
          <Link to="/contact">Contact LiftingCast</Link>
        </div>
      </Question>
    </div>
  );
};

export default About;
