import React from "react";
import CardAttempt from "./CardAttempt";
import range from "lodash/range";

const CardsAttempt = () => {
  console.log("CardsAttempt");
  return (
    <div className="cards-attempt">
      <div className="print-note">Must be printed in landscape</div>
      <div className="card-page">
        {range(1, 7).map((index: number) => {
          return <CardAttempt key={index} liftName="Squat" />;
        })}
      </div>
      <div className="card-page">
        {range(1, 7).map((index: number) => {
          return <CardAttempt key={index} liftName="Bench" />;
        })}
      </div>
      <div className="card-page">
        {range(1, 7).map((index: number) => {
          return <CardAttempt key={index} liftName="Deadlift" />;
        })}
      </div>
    </div>
  );
};

export default CardsAttempt;
