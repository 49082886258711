import React from "react";
import tinycolor from "tinycolor2";
import DisplayComponent from "./DisplayComponent";
import { isLbsMeet, getMeetUnits } from "util/meetHelper";
import { getAvailablePlates, getPlates } from "util/barLoad";
import { Attempt, Lifter, Meet, Plate, Platform } from "types";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import size from "lodash/size";
import min from "lodash/min";
import capitalize from "lodash/capitalize";
import map from "lodash/map";
import { merge } from "object-path-immutable";

const plateConfigKg: Record<string, PlateDisplay> = {
  plate_50: {
    text: ["5", "0"],
    width: 40,
    height: 200,
    fontSize: 24,
  },
  plate_25: {
    text: ["2", "5"],
    width: 30,
    height: 200,
    fontSize: 24,
  },
  plate_20: {
    text: ["2", "0"],
    width: 25,
    height: 200,
    fontSize: 24,
  },
  plate_15: {
    text: ["1", "5"],
    width: 22,
    height: 180,
    fontSize: 24,
  },
  plate_10: {
    text: ["1", "0"],
    width: 20,
    height: 160,
    fontSize: 20,
  },
  plate_5: {
    text: ["5"],
    width: 16,
    height: 150,
    fontSize: 18,
  },
  plate_2_5: {
    text: ["2", "•", "5"],
    width: 16,
    height: 125,
    fontSize: 16,
  },
  plate_2: {
    text: ["2"],
    width: 16,
    height: 115,
    fontSize: 16,
  },
  plate_1_5: {
    text: ["1", "•", "5"],
    width: 16,
    height: 105,
    fontSize: 16,
  },
  plate_1_25: {
    text: ["1", "•", "2", "5"],
    width: 16,
    height: 100,
    fontSize: 16,
  },
  plate_1: {
    text: ["1"],
    width: 16,
    height: 90,
    fontSize: 15,
  },
  plate_0_5: {
    text: ["0", "•", "5"],
    width: 16,
    height: 80,
    fontSize: 15,
  },
  plate_0_25: {
    text: ["0", "•", "2", "5"],
    width: 16,
    height: 65,
    fontSize: 12,
  },
};

const plateConfigLbs: Record<string, PlateDisplay> = {
  plate_100: {
    text: ["1", "0", "0"],
    width: 40,
    height: 200,
    fontSize: 24,
  },
  plate_55: {
    text: ["5", "5"],
    width: 35,
    height: 200,
    fontSize: 24,
  },
  plate_45: {
    text: ["4", "5"],
    width: 30,
    height: 200,
    fontSize: 24,
  },
  plate_35: {
    text: ["3", "5"],
    width: 25,
    height: 180,
    fontSize: 24,
  },
  plate_25: {
    text: ["2", "5"],
    width: 22,
    height: 155,
    fontSize: 24,
  },
  plate_10: {
    text: ["1", "0"],
    width: 20,
    height: 125,
    fontSize: 20,
  },
  plate_5: {
    text: ["5"],
    width: 16,
    height: 105,
    fontSize: 18,
  },
  plate_2_5: {
    text: ["2", "•", "5"],
    width: 16,
    height: 90,
    fontSize: 16,
  },
  plate_1_25: {
    text: ["1", "•", "2", "5"],
    width: 16,
    height: 80,
    fontSize: 16,
  },
  plate_0_5: {
    text: ["0", "•", "5"],
    width: 16,
    height: 75,
    fontSize: 15,
  },
  plate_0_25: {
    text: ["0", "•", "2", "5"],
    width: 16,
    height: 65,
    fontSize: 12,
  },
};

type PlateDisplay = {
  text: string[];
  width: number;
  height: number;
  fontSize: number;
};

type PlateAll = Plate & PlateDisplay;

const LoadingChart = ({
  meet,
  currentAttempt,
  id,
  currentLifter,
  platform,
}: {
  meet: Meet;
  currentAttempt: Attempt;
  id: string;
  currentLifter: Lifter | undefined;
  platform: Platform;
}) => {
  let isError = false;
  let plates: PlateAll[];

  const plateDisplayConfig: Record<string, PlateDisplay> = isLbsMeet(meet)
    ? plateConfigLbs
    : plateConfigKg;

  const plateConfig = merge(plateDisplayConfig, undefined, meet.plates);
  const availablePlates = getAvailablePlates(
    plateConfig as unknown as Record<string, Plate>
  );
  try {
    if (currentAttempt && !isEmpty(currentAttempt)) {
      plates = getPlates(
        currentAttempt.weight as number,
        availablePlates,
        platform
      ) as PlateAll[];
    } else {
      // if no attempt selected show all plates loaded
      plates = availablePlates as PlateAll[];
    }
  } catch (e) {
    isError = true;
  }

  return (
    <DisplayComponent style={{ top: 0, left: 900 }} id={id}>
      {({ sizeMultiplier }: { sizeMultiplier: number }) => {
        if (isError) {
          return (
            <div style={{ color: "red", fontSize: 42 * sizeMultiplier }}>
              Unable to load {currentAttempt.weight} {getMeetUnits(meet)} with
              available plates.
            </div>
          );
        }

        const liftName = get(currentAttempt, "liftName", "Lift");
        const rackHeight = get(currentLifter, `${liftName}RackHeight`, "");
        const lifterName = get(currentLifter, "name", "AAAAAAA AAAAAAA");

        const style = {
          padding: sizeMultiplier * 12,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          height: sizeMultiplier * 220,
        } as const;

        const rackHeightWrapperStyle = {
          width: 100 * sizeMultiplier,
        };

        const rackHeightStyle = {
          fontSize: min([
            (220 * sizeMultiplier) / size(rackHeight),
            28 * sizeMultiplier,
          ]),
        };

        const liftInfoStyle = {
          fontSize: 18 * sizeMultiplier,
        };

        const lifterNameWrapperStyle = {
          width: 100 * sizeMultiplier,
        };

        const lifterNameStyle = {
          fontSize: min([
            (160 * sizeMultiplier) / size(lifterName),
            14 * sizeMultiplier,
          ]),
        };

        const leftBarStyle = {
          borderTopLeftRadius: 7 * sizeMultiplier,
          borderBottomLeftRadius: 7 * sizeMultiplier,
          width: 90 * sizeMultiplier,
          height: 16 * sizeMultiplier,
          backgroundColor: "#595959",
          paddingRight: 4 * sizeMultiplier,
          fontSize: 14 * sizeMultiplier,
        };

        const leftBarLabelStyle = {
          fontSize: 12 * sizeMultiplier,
          paddingRight: 4 * sizeMultiplier,
        };

        const collarStyle = {
          marginRight: 2,
          borderRadius: 5 * sizeMultiplier,
          width: 30 * sizeMultiplier,
          height: 30 * sizeMultiplier,
          backgroundColor: "#595959",
        };

        const rightBarStyle = {
          borderTopRightRadius: 7 * sizeMultiplier,
          borderBottomRightRadius: 7 * sizeMultiplier,
          width: 20 * sizeMultiplier,
          height: 16 * sizeMultiplier,
          backgroundColor: "#595959",
        };

        const attemptChangeInProgressStyle = {
          fontSize: 40 * sizeMultiplier,
        };

        return (
          <div className="loading-chart" style={style}>
            {!!platform.attemptChangeInProgress && (
              <div
                className="attempt-change-overlay"
                style={attemptChangeInProgressStyle}
              >
                <div>ATTEMPT</div>
                <div>CHANGE</div>
                <div>IN PROGRESS</div>
              </div>
            )}
            <div className="rack-height-wrapper" style={rackHeightWrapperStyle}>
              <div className="rack-height" style={rackHeightStyle}>
                {rackHeight}
              </div>
            </div>
            <div className="lifter-name-wrapper" style={lifterNameWrapperStyle}>
              <div className="lifter-name" style={lifterNameStyle}>
                {lifterName}
              </div>
              <div className="lifter-name" style={liftInfoStyle}>
                {capitalize(liftName)} {get(currentAttempt, "attemptNumber")}
              </div>
              <div className="lifter-name" style={liftInfoStyle}>
                {get(currentAttempt, "weight", "000")}
                {getMeetUnits(meet)}
              </div>
            </div>
            <div className="left-bar" style={leftBarStyle}>
              {platform.barAndCollarsWeight && (
                <React.Fragment>
                  <div style={leftBarLabelStyle}>Bar & Cols</div>{" "}
                  {platform.barAndCollarsWeight}
                </React.Fragment>
              )}
            </div>
            {map(plates, (plate, index) => {
              const plateStyle = {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: plate.color,
                borderWidth: sizeMultiplier,
                borderStyle: "solid",
                borderColor: "gray",
                marginRight: 2 * sizeMultiplier,
                marginLeft: 2 * sizeMultiplier,
                borderRadius: 5 * sizeMultiplier,
                color: tinycolor
                  .mostReadable(plate.color, ["white", "black"])
                  .toHexString(),
                width: plate.width * sizeMultiplier,
                height: plate.height * sizeMultiplier,
                fontSize: plate.fontSize * sizeMultiplier,
              } as const;
              return (
                <div style={plateStyle} key={index}>
                  {map(plate.text, (char, index) => {
                    return <div key={`${char}-${index}`}>{char}</div>;
                  })}
                </div>
              );
            })}
            <div style={collarStyle} />
            <div style={rightBarStyle} />
          </div>
        );
      }}
    </DisplayComponent>
  );
};

export default LoadingChart;
