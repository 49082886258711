import React, { useState } from "react";
import { Link } from "react-router-dom";
import PouchInput from "components/PouchInput";
import PouchDateInput from "components/PouchDateInput";
import PouchCheckbox from "components/PouchCheckbox";
import Platforms from "./Platforms";
import Plates from "./Plates";
import EntryConfig from "./EntryConfig";
import DisplayConfig from "./DisplayConfig";
import MedalCounts from "./MedalCounts";
import SyncIcon from "icons/SyncIcon";
import CloudIcon from "icons/CloudIcon";
import FileIcon from "icons/FileIcon";
import ToolboxIcon from "icons/ToolboxIcon";
import "./SetupIndex.scss";
import { uploadDbToCloud, exportMeet } from "util/pouchAdapter";
import { login } from "util/pouchAuth";
import get from "lodash/get";
import { useMeet } from "util/useMeet";
import { useStatus } from "util/useStatus";
import DrugTestSelectionHelper from "./DrugTestSelectionHelper";
import PouchSelect from "components/PouchSelect";
import PastDueInvoicesModal from "components/pastDueInvoicesModal/PastDueInvoicesModal";
import InvoiceStatus from "components/invoiceStatus/InvoiceStatus";
import UploadModal from "./uploadModal/UploadModal";

const SetupIndex = ({ openPasswordModal }: { openPasswordModal: any }) => {
  const meet = useMeet();
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const { isOnline, isLocal, isSyncing } = useStatus();
  const validated = get(meet, "validated");

  const url = `https://liftingcast.com/meets/${get(meet, "_id")}/`;
  const backupUrl = `https://backup.liftingcast.com/meets/${get(meet, "_id")}/`;

  const [uploadLoading, setUploadLoading] = useState(false);

  const [pastDueInvoicesModalOpen, setPastDueInvoicesModalOpen] =
    React.useState<false | { name: string; invoiceUrl: string }[]>(false);

  const uploadToCloud = async ({
    password,
    confirmationToken,
    emailAddress,
  }: {
    password: string;
    confirmationToken: string;
    emailAddress: string;
  }) => {
    setUploadLoading(true);
    await uploadDbToCloud({
      meetId: meet._id,
      meetName: get(meet, "name") ?? "",
      password,
      confirmationToken,
      emailAddress,
      openPastDueInvoicesModal: (invoices: any) => {
        setPastDueInvoicesModalOpen(invoices);
      },
    });
    setUploadLoading(false);
    try {
      // @ts-expect-error
      if (typeof gtag !== "undefined") {
        // @ts-expect-error
        // eslint-disable-next-line no-undef
        gtag("event", "upload_meet", {
          meetId: meet._id,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const syncToCloud = () => {
    login(meet._id, null, openPasswordModal);
  };

  const stripeSecretKey = get(meet.restricted, "stripeSecretKey");

  const closeUploadModal = () => {
    setIsUploadModalOpen(false);
  };

  const openUploadModal = () => {
    setIsUploadModalOpen(true);
  };

  const onUploadCancel = () => {
    //
  };

  return (
    <div className="setup-index">
      <UploadModal
        isOpen={isUploadModalOpen}
        onRequestClose={closeUploadModal}
        onUpload={uploadToCloud}
        onCancel={onUploadCancel}
        meetId={meet._id}
        meet={meet}
      />
      {stripeSecretKey &&
        stripeSecretKey.startsWith("sk_") &&
        meet.entryConfig?.isOpen && (
          <div
            key="stripe-app-reg error"
            style={{ border: "1px solid red", padding: 20 }}
          >
            <h1 style={{ color: "red" }}>
              You must update your Stripe API key soon or your online
              registration will stop working.
            </h1>
            It is required to use a Restricted Key for your Stripe Secret Key.
            Restricted keys start with "rk_live_" or "rk_test_". You can
            generate a restricted key with the correct permissions by installing
            the LiftingCast Stripe App here{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://marketplace.stripe.com/apps/liftingcast-payment-link"
            >
              https://marketplace.stripe.com/apps/liftingcast-payment-link
            </a>
          </div>
        )}

      {!process.env.RELAY_SERVER && <InvoiceStatus />}
      <div>
        {isLocal && !isOnline && (
          <p>
            This meet exists only on your local computer. If you want to share
            it with others you must upload it to the cloud database. Using the
            cloud database is a paid feature. It is recommended to upload this
            meet as a way of backing up data. If you don't upload the meet then
            you should backup regularly by clicking the Download Meet Datafile
            button.
          </p>
        )}
        {isLocal && isOnline && !isSyncing && (
          <div className="sync-error-message">
            This meet is NOT currently syncing data to or from the cloud.
          </div>
        )}
        {!process.env.RELAY_SERVER && (
          <>
            {isLocal && !isOnline && (
              <button
                disabled={uploadLoading}
                style={{ width: 220 }}
                onClick={openUploadModal}
              >
                <CloudIcon />
                &nbsp;
                {uploadLoading ? "Uploading..." : "Upload To The Cloud (Paid)"}
              </button>
            )}
          </>
        )}
        {isLocal && isOnline && !isSyncing && (
          <button style={{ width: 220 }} onClick={syncToCloud}>
            <SyncIcon />
            &nbsp;Restart Sync To The Cloud
          </button>
        )}
        <button
          style={{ width: 220 }}
          className="data-file-button"
          onClick={() => exportMeet(meet._id)}
        >
          <FileIcon />
          &nbsp;Download Meet Data File
        </button>
      </div>
      <br />
      <Link
        style={{ width: 220 }}
        className="button-link"
        to={`/meets/${meet._id}/validator/connectionChecker`}
      >
        <ToolboxIcon />
        &nbsp;Problem Checker
      </Link>
      <div className="meet-info">
        <div className="entry-row">
          <label>Meet Name:</label>
          <div className="input-wrapper">
            <PouchInput
              type="text"
              meetId={meet._id}
              documentId={meet._id}
              name="name"
              value={get(meet, "name")}
            />
          </div>
        </div>
        <div className="entry-row">
          <label>Meet Date:</label>
          <div className="input-wrapper">
            <PouchDateInput
              meet={meet}
              documentId={meet._id}
              name="date"
              value={get(meet, "date")}
            />
          </div>
        </div>
        <div className="entry-row">
          <label>Contact Email:</label>
          <div className="input-wrapper">
            {validated && get(meet, "contactEmail")}
            {!validated && (
              <PouchInput
                type="text"
                meetId={meet._id}
                documentId={meet._id}
                name="contactEmail"
                value={get(meet, "contactEmail")}
                beforeSave={(value) =>
                  typeof value === "string" ? value.toLowerCase() : value
                }
              />
            )}
          </div>
        </div>
        <div className="entry-row">
          <label>Units:</label>
          <div className="input-wrapper">{get(meet, "units")}</div>
        </div>
        <div className="entry-row">
          <label>Federation:</label>
          <div className="input-wrapper">{get(meet, "federation")}</div>
        </div>
        <div className="entry-row">
          <label>Date Format:</label>
          <div className="input-wrapper">{get(meet, "dateFormat")}</div>
        </div>
        <div className="entry-row">
          <label>Meet ID:</label>
          <div className="input-wrapper">{get(meet, "_id")}</div>
        </div>
        {(!window.location.pathname.includes("backup") || isOnline) && (
          <div className="entry-row">
            <label>URL:</label>
            <div className="input-wrapper">
              <a href={url}>{url}</a>
            </div>
          </div>
        )}
        {(window.location.pathname.includes("backup") || isOnline) && (
          <div className="entry-row">
            <label>Backup URL:</label>
            <div className="input-wrapper">
              <a href={backupUrl}>{backupUrl}</a>
            </div>
          </div>
        )}
      </div>
      <div className="config-item">
        <div className="entry-row">
          <div className="input-wrapper">
            <PouchCheckbox
              meetId={meet._id}
              documentId={meet._id}
              name="showOnHomePage"
              value={get(meet, "showOnHomePage")}
              label={
                <span>
                  Show Link On Homepage
                  {!isOnline && (
                    <span style={{ whiteSpace: "normal" }}>
                      {" "}
                      (Won't show until meet is online)
                    </span>
                  )}
                </span>
              }
            />
          </div>
        </div>
        <div>
          Only check this for real meets. Do not check this for test meets.
        </div>
      </div>
      <div className="config-item">
        <div className="entry-row">
          <div className="input-wrapper">
            <PouchCheckbox
              meetId={meet._id}
              documentId={meet._id}
              name="lifterRackHeightsEnabled"
              value={get(meet, "lifterRackHeightsEnabled")}
              label="Allow lifters to enter rack heights"
            />
          </div>
        </div>
        <div>
          <Link
            to={`/meets/${meet._id}/rackHeights`}
          >{`${window.location.protocol}//${window.location.hostname}/meets/${meet._id}/rackHeights`}</Link>
        </div>
      </div>
      <div className="config-item">
        <div className="entry-row">
          <div className="input-wrapper">
            <PouchCheckbox
              meetId={meet._id}
              documentId={meet._id}
              name="virtualMeet"
              value={get(meet, "virtualMeet")}
              label="This is a Virtual Meet"
            />
          </div>
        </div>
        <div>Allows lifters to submit video attempts with online form.</div>
      </div>
      <div className="config-item">
        <div className="entry-row">
          <div className="input-wrapper">
            <PouchCheckbox
              meetId={meet._id}
              documentId={meet._id}
              name="runningTotal"
              value={get(meet, "runningTotal")}
              label="Use running total"
            />
          </div>
        </div>
        <div>
          If enabled lifters will have a total registered as soon as they have
          their first successful attempt. For example after the first successful
          squat attempt a lifter's total will be their best squat. If not
          enabled lifters will not register a total until they have completed a
          successful attempt in each lift the division is configured for. For
          example for a full powerlifting division the lifter will not have a
          total until they complete a successful deadlift.
        </div>
      </div>
      <Platforms meet={meet} />
      <Plates meet={meet} />
      <DisplayConfig meet={meet} />
      {!process.env.RELAY_SERVER && (
        <EntryConfig meet={meet} isOnline={isOnline} />
      )}
      <div className="entry-config">
        <div className="registration-title">Scoring</div>
        <div className="entry-row">
          <label style={{ width: 200 }}>Percent Of Record Source:</label>
          <div style={{ width: 150 }}>
            <PouchSelect
              meet={meet}
              document={meet}
              name="percentOfRecordSource"
              value={get(meet, "percentOfRecordSource", "WORLD")}
              options={() => [
                { label: "World Records", value: "WORLD" },
                { label: "National Records", value: "NATIONAL" },
              ]}
              clearable={false}
            />
          </div>
        </div>
        <div>
          When a division is configured to use % of record scoring (Pete Points)
          this setting controls if it uses % of National or World records.
        </div>
      </div>
      <MedalCounts meet={meet} />
      <DrugTestSelectionHelper meet={meet} />
      <PastDueInvoicesModal
        isOpen={!!pastDueInvoicesModalOpen}
        onRequestClose={() => setPastDueInvoicesModalOpen(false)}
        invoices={pastDueInvoicesModalOpen || []}
      />
    </div>
  );
};

export default SetupIndex;
